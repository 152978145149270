import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraTabs } from '../../components/AtiraTabs';
import { Flex } from '../../components/Flex';
import { UserHeader } from '../../components/UserHeader';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { shortenUrlActions } from '../../redux/url/url.slice';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { Breakpoints } from '../../theme/Breakpoints';
import { Spacing } from '../../theme/Spacing';
import { AtiraToast } from '../../utils/AtiraToast';
import { URLShortner } from './URLShortner';
import { UserURLs } from './UserURLs';

const HeaderWrapper = styled(Flex)`
  display: none;
  padding: ${Spacing.s};

  @media (min-width: ${Breakpoints.DESKTOP}) {
    display: flex;
  }
`;

export const URLRoute: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  useDocumentTitle(`${t('app.name').toUpperCase()} - ${t('common.my_urls')}`);

  const loggedInUserId = useAppSelector(
    userSliceSelectors.selectLoggedInUserId,
  )!;

  useEffect(() => {
    const getUserShortURLs = async () => {
      try {
        await dispatch(
          shortenUrlActions.getUserShortURLs({ userId: loggedInUserId }),
        ).unwrap();
      } catch (e: any) {
        console.log(e);
        AtiraToast.apiError(e);
      }
    };

    getUserShortURLs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex flexDirection="column">
      <AtiraTabs
        items={[
          {
            key: 'my-urls',
            label: t('side_menu.my_urls'),
            children: <UserURLs />,
          },
          {
            key: 'create-url',
            label: t('common.create'),
            children: <URLShortner />,
          },
        ]}
        sideComponent={
          <HeaderWrapper>
            <UserHeader />
          </HeaderWrapper>
        }
      />
    </Flex>
  );
};
