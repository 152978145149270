import { Drawer as AntdDrawer, DrawerProps as AntdDrawerProps } from 'antd';
import styled from 'styled-components';

import i18n, { AppLangs } from '../i18n';

const StyledDrawer = styled(AntdDrawer)`
  &.ant-drawer-content {
    background-color: ${({ theme }) => theme.light};
  }

  .ant-drawer-close svg {
    fill: ${({ theme }) => theme.textColor};
  }

  .ant-drawer-title {
    color: ${({ theme }) => theme.textColor};
  }
`;

type DrawerProps = AntdDrawerProps;

export const Drawer: React.FC<DrawerProps> = ({ children, ...props }) => {
  const lang = i18n.language as AppLangs;

  return (
    <StyledDrawer
      placement={lang === AppLangs.AR ? 'left' : 'right'}
      {...props}
    >
      {children}
    </StyledDrawer>
  );
};
