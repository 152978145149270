import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useTheme } from '../../ThemeContext';
import { AtiraCollapse } from '../../components/AtiraCollapse';
import { AtiraEmpty } from '../../components/AtiraEmpty';
import { PageMeta } from '../../model/meta/PageMeta';
import { adminSliceSelectors } from '../../redux/admin/admin.selector';
import { adminActions } from '../../redux/admin/admin.slice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { AdminReportRow } from './components/AdminReportRow';
import { AdminReportsReports } from './components/AdminReportsReports';

export const AdminReportsTab: React.FC = () => {
  const { theme } = useTheme();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;
  const reportedLinks = useAppSelector(adminSliceSelectors.selectReports);

  const items = useMemo(() => {
    const panelStyle: React.CSSProperties = {
      borderBottom: `1px solid ${theme.dark}`,
      backgroundColor: theme.light,
      border: '0',
    };

    return reportedLinks.map((atiraLink) => ({
      key: `${atiraLink?._id}`,
      label: <AdminReportRow atiraLink={atiraLink} />,
      children: (
        <AdminReportsReports reports={atiraLink.reports} key={atiraLink._id} />
      ),
      style: panelStyle,
    }));
  }, [reportedLinks, theme.sub]);

  useEffect(() => {
    dispatch(
      adminActions.getReports({
        userId,
        meta: PageMeta.create(),
      }),
    );
  }, [dispatch, userId]);

  if (!items.length) {
    return <AtiraEmpty description={t('admin.reports.empty')} />;
  }

  return (
    <AtiraCollapse
      items={items}
      collapsible="icon"
      expandIconPosition="end"
      accordion
    />
  );
};
