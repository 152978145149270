import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { Tooltip, TooltipProps } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { AtiraIcon } from './AtiraIcon';
import { Button } from './Button';

type DeleteButtonProps = {
  onDelete: VoidFunction;
  text?: string;
  icon?: IconProp;
  width?: string;
} & Partial<TooltipProps>;

export const DeleteButton: React.FC<DeleteButtonProps> = ({
  onDelete,
  text,
  icon,
  width = '25',
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={text || t('common.delete')} {...props}>
      <Button backgroundColor="transparent" onClick={onDelete}>
        <AtiraIcon size="2x" color="textColor" icon={icon || faTrash} />
      </Button>
    </Tooltip>
  );
};
