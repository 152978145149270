import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Input } from '../../../components/Input';
import { Text } from '../../../components/Text';
import { ForgotPasswordEmailDto } from '../../../model/user/dto/ForgotPasswordEmailDto';
import { useAppDispatch } from '../../../redux/store';
import { userActions } from '../../../redux/user/user.slice';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';
import { AtiraToast } from '../../../utils/AtiraToast';
import { ForgotPasswordBackStepButton } from './ForgotPasswordBackStepButton';

const StyledButton = styled(Button)`
  height: 3.5rem !important;
  width: 100%;
  background-color: ${({ theme }) => theme.main};
  font-size: 1rem;
  transition: background-color 0.2s;
  margin: 0;
  margin-top: ${Spacing.m};
  border-radius: ${Rounded.lg};

  &:hover {
    background-color: ${(props) => props.theme.lightMain};
  }
`;

const StyledInput = styled(Input)`
  height: 3.5rem;
  border: 2px solid ${(props) => props.theme.darkSub} !important;
  padding: 0.5rem;
  transition: border 0.2s;
  font-size: 1rem;
  margin-top: ${Spacing.xl};
  background-color: ${({ theme }) => theme.transparent};
  border-radius: ${Rounded.lg};

  &:focus {
    border: 2px solid #86b7fe !important;
  }
`;

const Wrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: ${Spacing.s};
  margin-top: ${Spacing.xl};
  width: 100%;
`;

type ForgotPasswordEmailProps = {
  nextStep: VoidFunction;
};

export const ForgotPasswordEmail: React.FC<ForgotPasswordEmailProps> = ({
  nextStep,
}) => {
  const [resetButtonLoading, setResetButtonLoading] = useState(false);

  const { control, handleSubmit, getValues } =
    useFormContext<ForgotPasswordEmailDto>();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const onNext = async () => {
    try {
      setResetButtonLoading(true);
      await dispatch(
        userActions.forgotPasswordEmail({ email: getValues('email') }),
      ).unwrap();

      AtiraToast.success(t('resetpassword.email.success'));
      nextStep();
    } catch (e: any) {
      AtiraToast.apiError(e, { autoClose: 6000 });
      console.log(e);
    } finally {
      setResetButtonLoading(false);
    }
  };

  return (
    <Wrapper>
      <Flex flexDirection="column" gap="l" width={'100%'}>
        <ForgotPasswordBackStepButton onClick={() => navigate('/login')} />

        <Text>{t('resetpassword.email.title')}</Text>

        <Text color="darkerSub">{t('resetpassword.email.description')}</Text>
      </Flex>

      <Controller
        name="email"
        control={control}
        rules={{ required: true }}
        render={({ field: { value, onChange }, formState: { errors } }) => (
          <StyledInput
            title={t('common.email')}
            type="email"
            value={value}
            onChange={onChange}
            id="email"
            valid={isEmpty(errors.email)}
            placeholder={t('resetpassword.email.input.placeholder')}
            required
          />
        )}
      />
      <StyledButton onClick={handleSubmit(onNext)} loading={resetButtonLoading}>
        {t('common.reset_password')}
      </StyledButton>
    </Wrapper>
  );
};
