import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraTabs } from '../../components/AtiraTabs';
import { Flex } from '../../components/Flex';
import { Text } from '../../components/Text';
import { UserHeader } from '../../components/UserHeader';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { CreateAtiraLinkDto } from '../../model/atira-link/dto/CreateAtiraLinkDto';
import { AtiraLinkThemes } from '../../model/atira-link/types/AtiraLinkThemes.enum';
import { atiraLinkSliceSelectors } from '../../redux/nerve/atira-link.selector';
import { useAppSelector } from '../../redux/store';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { Breakpoints } from '../../theme/Breakpoints';
import { Spacing } from '../../theme/Spacing';
import { AtiraLinkPreviewPhone } from './components/AtiraLinkPreviewPhone';
import { AtiraLinkContentTab } from './tabs/AtiraLinkContentTab';
import { AtiraLinkTemplatesTab } from './tabs/AtiraLinkTemplatesTab';

const HeaderPhoneWrapper = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.m};
  align-items: flex-end;
  position: sticky;
  top: 0;
  height: min-content;
  width: 0;

  @media (min-width: ${Breakpoints.DESKTOP}) {
    padding: ${Spacing.m};
    width: 100%;
    flex: 1;
  }
`;

const TabsWrapper = styled(Flex)`
  width: 100%;
  padding-bottom: ${Spacing.xxxl};
  margin-top: ${Spacing.m};

  @media (min-width: ${Breakpoints.DESKTOP}) {
    width: 80%;
    padding-bottom: 0;
  }
`;

export const AtiraLinkRoute: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  useDocumentTitle(
    `${t('app.name').toUpperCase()} - ${t('side_menu.my_link')}`,
  );

  const loggedInUser = useAppSelector(userSliceSelectors.selectLoggedInUser);
  const atiraLink = useAppSelector(atiraLinkSliceSelectors.selectUserAtiraLink);

  const formMethods = useForm<CreateAtiraLinkDto>({
    defaultValues: {
      title: atiraLink?.title || '',
      email: atiraLink?.email || '',
      phone: atiraLink?.phone || '',
      userId: loggedInUser?._id,
      theme: atiraLink?.theme || AtiraLinkThemes.BASIC,
      linkName: atiraLink?.linkName || '',
      bio: atiraLink?.bio,
      customLinks: (atiraLink?.customLinks || [])
        .concat([
          { label: '', value: '' },
          { label: '', value: '' },
        ])
        .slice(0, 2),
      image: atiraLink?.image,
      socials: atiraLink?.socials || [],
      map: atiraLink?.map,
      website: atiraLink?.website || '',
    },
  });

  return (
    <Flex flex={1} position="relative">
      <FormProvider {...formMethods}>
        <TabsWrapper>
          <AtiraTabs
            items={[
              {
                key: 'content',
                label: t('common.content'),
                children: <AtiraLinkContentTab />,
              },
              {
                key: 'templates',
                label: t('common.templates'),
                children: <AtiraLinkTemplatesTab />,
              },
              {
                key: 'design',
                label: t('common.design'),
                children: (
                  <Text color="textColor" fontSize="xl">
                    {t('common.feature.coming_soon')}
                  </Text>
                ),
              },
            ]}
          />
        </TabsWrapper>

        <HeaderPhoneWrapper>
          <UserHeader />

          <AtiraLinkPreviewPhone loading={loading} setLoading={setLoading} />
        </HeaderPhoneWrapper>
      </FormProvider>
    </Flex>
  );
};
