import { faArrowUpFromBracket } from '@fortawesome/free-solid-svg-icons/faArrowUpFromBracket';
import { faCircleUser } from '@fortawesome/free-solid-svg-icons/faCircleUser';
import { faMoon } from '@fortawesome/free-solid-svg-icons/faMoon';
import { faSun } from '@fortawesome/free-solid-svg-icons/faSun';
import { faUsersGear } from '@fortawesome/free-solid-svg-icons/faUsersGear';
import { MenuProps, Tooltip } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Themes, useTheme } from '../ThemeContext';
import { UserRoles } from '../model/user/types/UserRoles.enum';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { userSliceSelectors } from '../redux/user/user.selector';
import { userActions } from '../redux/user/user.slice';
import { ShareDrawer } from '../routes/shared/components/ShareDrawer';
import { Breakpoints } from '../theme/Breakpoints';
import { Rounded } from '../theme/Rounded';
import { Spacing } from '../theme/Spacing';
import { truncateString } from '../utils/String';
import { AtiraDropdown } from './AtiraDropdown';
import { AtiraIcon } from './AtiraIcon';
import { Button } from './Button';
import { Flex } from './Flex';
import { AtiraImage } from './Image';
import { LanguageSwitcher } from './LanguageSwitcher';
import { Text } from './Text';

const Container = styled(Flex)`
  align-items: center;
  gap: ${Spacing.l};
  display: none;

  @media (min-width: ${Breakpoints.DESKTOP}) {
    display: flex;
  }
`;

const UserProfileWrapper = styled(Flex)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${Rounded.circle};
  width: 4rem;
  height: 4rem;
  overflow: hidden;
  cursor: pointer;
`;

const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.transparent};
  margin: 0;
  padding: 0;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.textColor};

  &:hover {
    color: ${({ theme }) => theme.textColor};
  }
`;

export const UserHeader: React.FC = () => {
  const [openShareDrawer, setOpenShareDrawer] = useState(false);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { theme, toggleTheme, currentTheme } = useTheme();

  const user = useAppSelector(userSliceSelectors.selectLoggedInUser);

  const onLogout = () => {
    dispatch(userActions.logout());
    if (currentTheme === Themes.DARK) {
      toggleTheme();
    }
    navigate('/login', { replace: true });
  };

  const items: MenuProps['items'] = [
    {
      key: 'g1',
      type: 'group',
      children: [
        {
          key: '2',
          label: (
            <Text fontSize="m" color="textColor">
              {user?.email ? (
                <Tooltip title={user.email}>
                  {truncateString(user.email)}
                </Tooltip>
              ) : (
                t('common.user_email')
              )}
            </Text>
          ),
        },
      ],
    },
    {
      key: '3',
      label: <hr />,
    },
    {
      key: '6',
      label: (
        <Button
          onClick={onLogout}
          fontSize="1rem"
          backgroundColor="transparent"
          color="textColor"
          border={`0.1rem solid ${theme.main}`}
          textAlign="center"
          width="100%"
        >
          {t('header.logout')}
        </Button>
      ),
      className: 'logout-button',
    },
  ];

  return (
    <Container>
      <Tooltip title={t('common.share')}>
        <StyledButton
          onClick={() => setOpenShareDrawer(true)}
          icon={faArrowUpFromBracket}
          color="textColor"
          iconWidth="2xl"
        />
      </Tooltip>

      <Tooltip title={t('header.toggle_theme')}>
        <StyledButton
          onClick={toggleTheme}
          icon={currentTheme === Themes.LIGHT ? faMoon : faSun}
          color="textColor"
          iconWidth="2xl"
        />
      </Tooltip>
      <LanguageSwitcher color="textColor" />

      <ShareDrawer
        open={openShareDrawer}
        onClose={() => setOpenShareDrawer(false)}
      />

      {user?.roles?.includes(UserRoles.ADMIN) ? (
        <Tooltip title={t('admin.header.title')}>
          <StyledLink to={'/admin'}>
            <AtiraIcon icon={faUsersGear} size="2x" />
          </StyledLink>
        </Tooltip>
      ) : null}

      <AtiraDropdown
        overlayStyle={{
          width: '15rem',
          borderRadius: '0.5rem',
        }}
        items={items}
        placement="bottomRight"
        arrow={{ pointAtCenter: true }}
        trigger={['click']}
      >
        <UserProfileWrapper>
          {user?.picture ? (
            <AtiraImage src={user.picture} />
          ) : (
            <AtiraIcon icon={faCircleUser} size="4x" color="main" />
          )}
        </UserProfileWrapper>
      </AtiraDropdown>
    </Container>
  );
};
