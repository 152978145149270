import { Table } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraEmpty } from '../../components/AtiraEmpty';
import { Flex } from '../../components/Flex';
import { Text } from '../../components/Text';
import { WarningModal } from '../../components/WarningModal';
import i18n, { AppLangs } from '../../i18n';
import { AtiraURL } from '../../model/url/AtiraURL';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { urlSliceSelectors } from '../../redux/url/url.selector';
import { shortenUrlActions } from '../../redux/url/url.slice';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { AtiraToast } from '../../utils/AtiraToast';
import { createUserURLsTableColumns } from './createUserURLsTableColumns';

const TableWrapper = styled.div`
  .ant-table-thead .ant-table-cell {
    background-color: ${({ theme }) => theme.light} !important;
    color: ${({ theme }) => theme.textColor} !important;
  }

  .ant-table-tbody {
    background-color: ${({ theme }) => theme.light} !important;
  }

  .ant-table-column-sorter svg {
    fill: ${({ theme }) => theme.textColor} !important ;
  }

  .enabled-url {
    background-color: initial !important;
  }

  .disabled-url {
    background-color: ${({ theme }) => theme.darkSub} !important;
  }
`;

export const UserURLs: React.FC = () => {
  const [deleteURLLoading, setDeleteURLLoading] = useState(false);
  const [deleteURLModalVisibile, setDeleteURLModalVisibile] = useState(false);
  useState<number | undefined>(undefined);
  const [currentURLId, setCurrentURLId] = useState('');

  const { t } = useTranslation();

  const loggedInUserId = useAppSelector(
    userSliceSelectors.selectLoggedInUserId,
  )!;
  const userShortURLs = useAppSelector((s) =>
    urlSliceSelectors.selectShortURLs(s, loggedInUserId),
  );

  const dispatch = useAppDispatch();

  if (!userShortURLs.length) {
    return <AtiraEmpty description={t('shorten_url.no_urls')} />;
  }

  const showDeleteWarning = (urlId: string) => {
    setCurrentURLId(urlId);
    setDeleteURLModalVisibile(true);
  };

  const deleteURL = async () => {
    try {
      setDeleteURLLoading(true);

      await dispatch(
        shortenUrlActions.deleteURL({
          urlId: currentURLId,
          userId: loggedInUserId,
        }),
      ).unwrap();

      await dispatch(
        shortenUrlActions.getUserShortURLs({ userId: loggedInUserId }),
      ).unwrap();

      AtiraToast.success(t('shorten_url.delete.success'));
    } catch (e: any) {
      AtiraToast.apiError(e);
    } finally {
      setDeleteURLLoading(false);
      setDeleteURLModalVisibile(false);
      setCurrentURLId('');
    }
  };

  const enableURL = async (urlId: string) => {
    try {
      await dispatch(
        shortenUrlActions.enableUserURL({
          urlId,
          userId: loggedInUserId,
        }),
      ).unwrap();

      await dispatch(
        shortenUrlActions.getUserShortURLs({ userId: loggedInUserId }),
      ).unwrap();

      AtiraToast.success(t('url.disable.success'));
    } catch (e: any) {
      console.log(e);
      AtiraToast.apiError(e);
    }
  };

  const disableURL = async (urlId: string) => {
    try {
      await dispatch(
        shortenUrlActions.disableUserURL({
          urlId,
          userId: loggedInUserId,
        }),
      ).unwrap();
      AtiraToast.success(t('url.disable.success'));

      await dispatch(
        shortenUrlActions.getUserShortURLs({ userId: loggedInUserId }),
      ).unwrap();
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    }
  };

  type TableKeys = keyof AtiraURL | 'actions';

  const tableColumnsKeys: TableKeys[] = [
    'actions',
    'createdAt',
    'originalURL',
    'shortURL',
    'visits',
  ];

  const tableColumns = createUserURLsTableColumns(tableColumnsKeys, {
    t,
    disable: disableURL,
    enable: enableURL,
    delete: showDeleteWarning,
  });

  console.log(userShortURLs);

  return (
    <Flex width={'100%'} height={'100%'} flexDirection="column">
      <Flex flexDirection="column">
        <Flex
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          padding="s"
        >
          <Text>{t('shorten_url.your_short_urls')}</Text>

          <Text>
            {t('common.total')} {userShortURLs.length}
          </Text>
        </Flex>

        <TableWrapper>
          <Table
            key={JSON.stringify(tableColumns)}
            columns={tableColumns}
            dataSource={userShortURLs}
            direction={i18n.language === AppLangs.AR ? 'rtl' : 'ltr'}
            scroll={{ x: 'max-content' }}
            rowClassName={(url) =>
              url.enabled ? 'enabled-url' : 'disabled-url'
            }
            rowHoverable={false}
            pagination={false}
          />
        </TableWrapper>
      </Flex>

      <WarningModal
        onClose={() => setDeleteURLModalVisibile(false)}
        open={deleteURLModalVisibile}
        title={t('shorten_url.delete_modal.title')}
        description={t('shorten_url.delete_modal.description')}
        onConfirm={deleteURL}
        loading={deleteURLLoading}
      />

      <Text align="center">{t('shorten_url.my_urls.footer_paragraph')}</Text>
    </Flex>
  );
};
