import isString from 'lodash/isString';

import { CreateAtiraLinkDto } from '../../model/atira-link/dto/CreateAtiraLinkDto';
import { EditAtiraLinkDto } from '../../model/atira-link/dto/EditAtiraLinkDto';
import { GenericWithUserId } from '../../model/shared/GenericWithUserId';
import { DeleteAtiraLinkDto } from '../../redux/nerve/dto/DeleteAtiraLinkDto';
import { GenericDisableEnableAtiraLinkDto } from '../../redux/nerve/dto/GenericDisableEnableAtiraLinkDto';
import { AtiraApi } from '../AxiosClient';

export class AtiraLinkAxios {
  static async createAtiraLink(dto: CreateAtiraLinkDto) {
    const formData = new FormData();

    Object.entries(dto).forEach(([key, value]) => {
      formData.append(
        key,
        key === 'image' || isString(value) ? value : JSON.stringify(value),
      );
    });

    return AtiraApi.post<void>('/atira-link/create', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(({ data }) => data);
  }

  static async editAtiraLinkDto(dto: EditAtiraLinkDto) {
    const formData = new FormData();
    Object.entries(dto).forEach(([key, value]) => {
      formData.append(
        key,
        key === 'image' || isString(value) ? value : JSON.stringify(value),
      );
    });

    return AtiraApi.patch<void>(
      `/atira-link/${dto.atiraLinkId}/edit`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    ).then(({ data }) => data);
  }

  static async getUserAtiraLink(dto: GenericWithUserId) {
    return AtiraApi.post(`/atira-link/get`, dto).then(({ data }) => data);
  }

  static async deleteAtiraLink(dto: DeleteAtiraLinkDto) {
    return AtiraApi.post(`/atira-link/delete`, dto).then(({ data }) => data);
  }
  static async disableAtiraLink(dto: GenericDisableEnableAtiraLinkDto) {
    return AtiraApi.post(`/atira-link/disable`, dto).then(({ data }) => data);
  }
  static async enableAtiraLink(dto: GenericDisableEnableAtiraLinkDto) {
    return AtiraApi.post(`/atira-link/enable`, dto).then(({ data }) => data);
  }
}
