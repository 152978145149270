import { Footer } from 'antd/es/layout/layout';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Images } from '../assets';
import { AtiraColumn } from '../components/AtiraColumn';
import { AtiraRow } from '../components/AtiraRow';
import { Flex } from '../components/Flex';
import { AtiraImage } from '../components/Image';
import { Text } from '../components/Text';
import { Spacing } from '../theme/Spacing';

const StyledFooter = styled(Footer)`
  background: linear-gradient(
    90deg,
    rgb(151, 11, 170) 0%,
    rgb(35, 40, 147) 99%
  );
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: ${Spacing.l};
`;

export const PublicFooter: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StyledFooter>
      <AtiraRow justify={'space-evenly'} gutter={[20, 20]}>
        <AtiraColumn xs={24} sm={24} md={12} lg={6} xl={6}>
          <Flex flexDirection="column" alignItems="center" gap="m">
            <AtiraImage
              src={Images.AtiraLinkSubLogo}
              style={{ maxWidth: '17rem' }}
            />
            <Flex width={'90%'} justifyContent="center">
              <Text
                align="center"
                color="lightTextColor"
                wordBreak="break-word"
                fontSize="l"
              >
                {t('app.description')}
              </Text>
            </Flex>
          </Flex>
        </AtiraColumn>

        <AtiraColumn xs={24} sm={24} md={12} lg={6} xl={6}>
          <Flex flexDirection="column" gap="m" alignItems="center">
            <Text fontSize="l" fontWeight={'bold'} color="lightTextColor">
              {t('common.links')}
            </Text>
            <Flex flexDirection="column" alignItems="center" gap="s">
              <Link to={''}>
                <Text color="lightTextColor">{t('common.how_to')} </Text>
              </Link>
              <Link to={''}>
                <Text color="lightTextColor">{t('common.templates')}</Text>
              </Link>
              <Link to={''}>
                <Text color="lightTextColor">{t('common.pricing')}</Text>
              </Link>
              <Link to={''}>
                <Text color="lightTextColor">{t('common.help')}</Text>
              </Link>
            </Flex>
          </Flex>
        </AtiraColumn>

        <AtiraColumn xs={24} sm={24} md={12} lg={6} xl={6}>
          <Flex flexDirection="column" gap="m" alignItems="center">
            <Text fontSize="l" fontWeight={'bold'} color="lightTextColor">
              {t('common.links')}
            </Text>
            <Flex flexDirection="column" alignItems="center" gap="s">
              <Link to={''}>
                <Text color="lightTextColor">{t('common.how_to')} </Text>
              </Link>
              <Link to={''}>
                <Text color="lightTextColor">{t('common.templates')}</Text>
              </Link>
              <Link to={''}>
                <Text color="lightTextColor">{t('common.pricing')}</Text>
              </Link>
              <Link to={''}>
                <Text color="lightTextColor">{t('common.help')}</Text>
              </Link>
            </Flex>
          </Flex>
        </AtiraColumn>

        <AtiraColumn xs={24} sm={24} md={12} lg={6} xl={6}>
          <Flex flexDirection="column" gap="m" alignItems="center">
            <Text fontSize="l" fontWeight={'bold'} color="lightTextColor">
              {t('common.links')}
            </Text>
            <Flex flexDirection="column" alignItems="center" gap="s">
              <Link to={''}>
                <Text color="lightTextColor">{t('common.how_to')} </Text>
              </Link>
              <Link to={''}>
                <Text color="lightTextColor">{t('common.templates')}</Text>
              </Link>
              <Link to={''}>
                <Text color="lightTextColor">{t('common.pricing')}</Text>
              </Link>
              <Link to={''}>
                <Text color="lightTextColor">{t('common.help')}</Text>
              </Link>
            </Flex>
          </Flex>
        </AtiraColumn>
      </AtiraRow>

      <Text align="center" color="lightTextColor">
        {t('app.all_rights_reserved')}
      </Text>
    </StyledFooter>
  );
};
