import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { ForgotPasswordEmailDto } from '../../../model/user/dto/ForgotPasswordEmailDto';
import { ForgotPasswordNewPasswordDto } from '../../../model/user/dto/ForgotPasswordNewPasswordDto';
import { ForgotPasswordTokenDto } from '../../../model/user/dto/ForgotPasswordTokenDto';
import { Breakpoints } from '../../../theme/Breakpoints';
import { Spacing } from '../../../theme/Spacing';
import { RegisterWrapper } from '../RegisterWrapper';
import { ForgotPasswordConfirm } from './ForgotPasswordConfirm';
import { ForgotPasswordEmail } from './ForgotPasswordEmail';
import { ForgotPasswordNewPassword } from './ForgotPasswordNewPassword';
import { ForgotPasswordSuccess } from './ForgotPasswordSuccess';
import { ForgotPasswordToken } from './ForgotPasswordToken';

const Wrapper = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;

  @media (min-width: ${Breakpoints.DESKTOP}) {
    padding: 0 ${Spacing.xxxl};
  }
`;

const WelcomeMessage = styled(Text)`
  color: ${({ theme }) => theme.main};
  font-weight: 600;
  font-size: 1.8rem;
  text-align: center;
  margin-bottom: ${Spacing.m};
  border-bottom: ${({ theme }) => `1px solid ${theme.lightergray}`};
  padding: ${Spacing.s} 0;
  width: 100%;
  white-space: nowrap;
  display: none;

  @media (min-width: ${Breakpoints.DESKTOP}) {
    display: block;
  }
`;

const ComponentWrapper = styled(Flex)`
  @media (min-width: ${Breakpoints.TABLET}) {
    padding: 0 ${Spacing.xl};
  }
`;

enum FORGOT_PASS_STEPS {
  EMAIL = 'EMAIL',
  TOKEN = 'TOKEN',
  NEW = 'NEW_PASSWORD',
  CONFIRM = 'CONFIRM',
  SUCCESS = 'SUCCESS',
}

export const ForgotPassword: React.FC = () => {
  const [step, setStep] = useState<FORGOT_PASS_STEPS>(FORGOT_PASS_STEPS.EMAIL);
  const formMethods = useForm<
    ForgotPasswordEmailDto &
      ForgotPasswordTokenDto &
      ForgotPasswordNewPasswordDto
  >({
    defaultValues: { email: '', token: '', newPassword: '' },
  });

  const { t } = useTranslation();

  const component = () => {
    switch (step) {
      case FORGOT_PASS_STEPS.EMAIL:
        return (
          <ForgotPasswordEmail
            nextStep={() => setStep(FORGOT_PASS_STEPS.TOKEN)}
          />
        );
      case FORGOT_PASS_STEPS.TOKEN:
        return (
          <ForgotPasswordToken
            nextStep={() => setStep(FORGOT_PASS_STEPS.CONFIRM)}
            prevStep={() => setStep(FORGOT_PASS_STEPS.EMAIL)}
          />
        );
      case FORGOT_PASS_STEPS.CONFIRM:
        return (
          <ForgotPasswordConfirm
            nextStep={() => setStep(FORGOT_PASS_STEPS.NEW)}
            prevStep={() => setStep(FORGOT_PASS_STEPS.TOKEN)}
          />
        );
      case FORGOT_PASS_STEPS.NEW:
        return (
          <ForgotPasswordNewPassword
            nextStep={() => setStep(FORGOT_PASS_STEPS.SUCCESS)}
            prevStep={() => setStep(FORGOT_PASS_STEPS.CONFIRM)}
          />
        );
      case FORGOT_PASS_STEPS.SUCCESS:
        return <ForgotPasswordSuccess />;
      default:
        return (
          <ForgotPasswordEmail
            nextStep={() => setStep(FORGOT_PASS_STEPS.TOKEN)}
          />
        );
    }
  };

  return (
    <RegisterWrapper>
      <Wrapper>
        <FormProvider {...formMethods}>
          <Flex flexDirection="column">
            <WelcomeMessage>{t('register.welcome_message')}</WelcomeMessage>
            <ComponentWrapper>{component()}</ComponentWrapper>
          </Flex>
        </FormProvider>
      </Wrapper>
    </RegisterWrapper>
  );
};
