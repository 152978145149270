import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';
import { ForgotPasswordBackStepButton } from './ForgotPasswordBackStepButton';

const Wrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: ${Spacing.s};
  margin-top: ${Spacing.xl};
`;

const StyledButton = styled(Button)`
  height: 3.5rem !important;
  width: 100%;
  background-color: ${({ theme }) => theme.main};
  font-size: 1.2rem;
  transition: background-color 0.2s;
  margin: 0;
  margin-top: ${Spacing.m};
  border-radius: ${Rounded.lg} !important;

  &:hover {
    background-color: ${(props) => props.theme.lightMain};
  }
`;

type ForgotPasswordConfirmProps = {
  nextStep: VoidFunction;
  prevStep: VoidFunction;
};

export const ForgotPasswordConfirm: React.FC<ForgotPasswordConfirmProps> = ({
  nextStep,
  prevStep,
}) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Flex flexDirection="column" gap="l" width={'100%'}>
        <ForgotPasswordBackStepButton onClick={prevStep} />

        <Text>{t('resetpassword.confirm.title')}</Text>

        <Text color="darkerSub">{t('resetpassword.confirm.description')}</Text>

        <StyledButton onClick={nextStep}>{t('common.confirm')}</StyledButton>
      </Flex>
    </Wrapper>
  );
};
