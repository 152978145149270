import { faEllipsis } from '@fortawesome/free-solid-svg-icons/faEllipsis';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { Dropdown, MenuProps, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraIcon } from '../../../components/AtiraIcon';
import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { AtiraLink } from '../../../model/atira-link/AtiraLink';
import { Breakpoints } from '../../../theme/Breakpoints';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';

const Container = styled(Flex)`
  background-color: ${({ theme }) => theme.white};
  align-items: center;
  justify-content: space-between;
  border-radius: ${Rounded.md};
  width: 100%;
`;

const StyledButton = styled(Button)`
  margin: 0;
`;

const StyledSpan = styled.span`
  background-color: ${({ theme }) => theme.danger};
  color: white;
  padding: 0.2rem 0.3rem;
  border-radius: ${Rounded.md};
  font-size: 0.8rem;
`;

const DropdownButton = styled(Button)`
  margin: 0;
  padding: 0;
  background-color: ${({ theme }) => theme.transparent};
  color: ${({ theme }) => theme.main};
  margin-inline-start: auto;
`;

const MenuButton = styled(Button)`
  margin: 0;
  padding: ${Spacing.s} ${Spacing.m};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
  gap: ${Spacing.s};
  background-color: ${({ theme }) => theme.transparent};
  color: ${({ theme }) => theme.main};
  width: 100%;
`;

const ActionsWrapper = styled(Flex)`
  align-items: center;
  gap: ${Spacing.m};
  display: none;

  @media (min-width: ${Breakpoints.DESKTOP}) {
    display: flex;
  }
`;

const StyledDropdown = styled(Dropdown)`
  display: flex;

  @media (min-width: ${Breakpoints.DESKTOP}) {
    display: none;
  }
`;

type AdminReportRowProps = {
  atiraLink: AtiraLink;
};

export const AdminReportRow: React.FC<AdminReportRowProps> = ({
  atiraLink,
}) => {
  const { t } = useTranslation();

  const onVisitLink = () => {
    window.open(`https://atrl.ink/${atiraLink.linkName}`, '_blank');
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <MenuButton onClick={onVisitLink}>
          {t('common.visit')}
          <AtiraIcon icon={faEye} color="main" />
        </MenuButton>
      ),
    },
  ];

  return (
    <Container>
      <Flex flexDirection="column" gap="m" width={'100%'}>
        <Flex alignItems="center" gap="s">
          <Text fontSize="xm" fontWeight={'bold'}>
            {atiraLink.title}
          </Text>

          <StyledSpan>{`${atiraLink.reports?.length} ${t('common.report')}`}</StyledSpan>

          <StyledDropdown menu={{ items }}>
            <DropdownButton icon={faEllipsis} iconWidth="lg" />
          </StyledDropdown>
        </Flex>

        <Text fontSize="xm">{atiraLink._id}</Text>
      </Flex>

      <ActionsWrapper>
        <Tooltip title={t('common.visit')}>
          <StyledButton icon={faEye} onClick={onVisitLink} />
        </Tooltip>
      </ActionsWrapper>
    </Container>
  );
};
