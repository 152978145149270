import React from 'react';
import { useTranslation } from 'react-i18next';

import { AtiraTabs } from '../../components/AtiraTabs';
import { AdminReportsTab } from './AdminReportsTab';
import { AdminSearchUsersTab } from './AdminSearchUserTab';
import { AdminStatsTab } from './AdminStatsTab';

export const AdminRoute: React.FC = () => {
  const { t } = useTranslation();

  return (
    <AtiraTabs
      items={[
        {
          key: 'stats',
          label: t('admin.header.title'),
          children: <AdminStatsTab />,
        },
        {
          key: 'search',
          label: t('common.search'),
          children: <AdminSearchUsersTab />,
        },
        {
          key: 'reports',
          label: t('common.reports'),
          children: <AdminReportsTab />,
        },
      ]}
    />
  );
};
