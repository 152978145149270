import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '../../components/Button';
import { Flex } from '../../components/Flex';
import { Input } from '../../components/Input';
import { Text } from '../../components/Text';
import { GoogleButton } from '../../components/logins/GoogleButton';
import { LoginDto } from '../../model/user/dto/LoginDto';
import { LoginWithGoogleReturnPath } from '../../model/user/types/LoginWithGoogleReturnPath.enum';
import { useAppDispatch } from '../../redux/store';
import { userActions } from '../../redux/user/user.slice';
import { Breakpoints } from '../../theme/Breakpoints';
import { Rounded } from '../../theme/Rounded';
import { Spacing } from '../../theme/Spacing';
import { AtiraToast } from '../../utils/AtiraToast';
import { Regexes } from '../../utils/String';
import { RegisterWrapper } from './RegisterWrapper';

const InnerWrapper = styled(Flex)`
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${Spacing.l};
  padding-top: ${Spacing.m};

  @media (min-width: ${Breakpoints.TABLET}) {
    padding: 0 ${Spacing.m};
  }
`;

const SignUpLink = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.main};
  font-size: 1rem;
`;

const StyledButton = styled(Button)`
  height: 3rem !important;
  width: 100%;
  background-color: ${({ theme }) => theme.main};
  font-size: 1rem;
  transition: background-color 0.2s;
  margin: 0;
  border-radius: ${Rounded.lg};

  &:hover {
    background-color: ${(props) => props.theme.lightMain};
  }
`;

const PasswordButton = styled(Button)`
  background-color: ${(props) => props.theme.transparent};
  color: #4285f4;
  font-size: 0.8rem;
  margin: 0;
  padding: 0;
  margin-inline-start: auto;
`;

const StyledInput = styled(Input)`
  height: 3.2rem;
  border: 1px solid ${(props) => props.theme.lightergray} !important;
  padding: 0.5rem;
  transition: border 0.2s;
  font-size: 1rem;
  border-radius: ${Rounded.lg};

  &:focus {
    border: 1px solid #86b7fe !important;
  }
`;

const StyledHr = styled.hr`
  background-color: ${(props) => props.theme.darkerSub};
  height: 1px;
  border: 0;
  opacity: 1;
  width: 100%;
  margin: ${Spacing.m} 0;
`;

const WelcomeMessage = styled(Text)`
  color: ${({ theme }) => theme.main};
  font-weight: 600;
  font-size: 1.8rem;
  text-align: center;
  margin-bottom: ${Spacing.m};
  border-bottom: ${({ theme }) => `1px solid ${theme.lightergray}`};
  padding: ${Spacing.s} 0;
  width: 100%;
  white-space: nowrap;
`;

const OuterWrapper = styled(Flex)`
  flex-direction: column;
  padding: 0 ${Spacing.s};

  @media (min-width: ${Breakpoints.TABLET}) {
    padding: 0 ${Spacing.xxl};
  }

  @media (min-width: ${Breakpoints.DESKTOP}) {
    padding: 0 ${Spacing.xxxl};
  }
`;

export const Login: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { control, handleSubmit, getValues } = useForm<LoginDto>({
    defaultValues: { email: '', password: '' },
  });

  const { t } = useTranslation();
  const navigte = useNavigate();

  const onNext = async () => {
    try {
      setLoading(true);
      await dispatch(userActions.login(getValues())).unwrap();
      navigate('/dashboard', { replace: true });
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <RegisterWrapper>
      <OuterWrapper>
        <WelcomeMessage>{t('register.welcome_message')}</WelcomeMessage>

        <InnerWrapper>
          <Controller
            name="email"
            rules={{ required: true, pattern: Regexes.EMAIL_REGEX }}
            control={control}
            render={({ field: { value, onChange }, formState: { errors } }) => (
              <StyledInput
                title={t('common.email')}
                type="email"
                value={value}
                onChange={onChange}
                placeholder={t('common.email')}
                id="email"
                valid={isEmpty(errors.email)}
                errorMessage={errors.email?.message || t('signup.error.email')}
                required
              />
            )}
          />

          <Flex flexDirection="column" width={'100%'} gap="s">
            <Controller
              name="password"
              control={control}
              rules={{ required: true }}
              render={({
                field: { value, onChange },
                formState: { errors },
              }) => (
                <StyledInput
                  title={t('common.password')}
                  value={value}
                  type="password"
                  onChange={onChange}
                  placeholder={t('common.password')}
                  id="password"
                  valid={isEmpty(errors.password)}
                  errorMessage={
                    errors.password?.message || t('signup.error.password')
                  }
                  required
                />
              )}
            />

            <PasswordButton
              onClick={() => navigte('/forgot-password')}
              title={t('login.forgot_password')}
            />
          </Flex>

          <Flex flexDirection="column" gap="m" width={'100%'} marginTop="m">
            <StyledButton loading={loading} onClick={handleSubmit(onNext)}>
              {t('common.login')}
            </StyledButton>

            <GoogleButton returnPath={LoginWithGoogleReturnPath.LOGIN}>
              {t('sign_in_with_google')}
            </GoogleButton>

            <StyledHr />

            <Flex gap="s" alignItems="center">
              <Text>{t('login.do_not_have_account')}</Text>

              <SignUpLink to={'/signup'}>{t('common.signup')}</SignUpLink>
            </Flex>
          </Flex>
        </InnerWrapper>
      </OuterWrapper>
    </RegisterWrapper>
  );
};
