import { Dropdown, DropdownProps, MenuProps } from 'antd';
import React, { Fragment } from 'react';
import { createGlobalStyle } from 'styled-components';

import { useTheme } from '../ThemeContext';

const MenuThemedStyle = createGlobalStyle`
  .ant-dropdown .ant-dropdown-menu {
    background-color: ${({ theme }) => theme.light};
  }

  .ant-dropdown-arrow::after, .ant-dropdown-arrow::before {
    background-color: ${({ theme }) => theme.light} !important;

  }

  li.ant-dropdown-menu-item span { 
    color: ${({ theme }) => theme.textColor};
  }
`;

interface AtiraDropdownProps extends DropdownProps {
  children: React.ReactNode;
  items: MenuProps['items'];
}

export const AtiraDropdown: React.FC<AtiraDropdownProps> = ({
  children,
  items,
  ...props
}) => {
  const { theme } = useTheme();

  return (
    <Fragment>
      <Dropdown menu={{ items }} {...props}>
        {children}
      </Dropdown>

      <MenuThemedStyle theme={theme} />
    </Fragment>
  );
};
