import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import VerificationInput from 'react-verification-input';
import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { ForgotPasswordTokenDto } from '../../../model/user/dto/ForgotPasswordTokenDto';
import { SignupDto } from '../../../model/user/dto/SignupDto';
import { useAppDispatch } from '../../../redux/store';
import { userActions } from '../../../redux/user/user.slice';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';
import { AtiraToast } from '../../../utils/AtiraToast';
import { truncateString } from '../../../utils/String';
import { ForgotPasswordBackStepButton } from './ForgotPasswordBackStepButton';

const InputWrapper = styled(Flex)<{ valid: boolean }>`
  width: 100%;
  margin-top: ${Spacing.xl};

  .vi__container {
    width: 100% !important;
  }

  .vi__character {
    border: ${({ theme, valid }) =>
      `2px solid ${valid ? theme.darkSub : theme.danger}`} !important;
    background-color: ${({ theme }) => theme.transparent} !important;
    border-radius: ${Rounded.lg} !important;
  }

  .vi__character--filled {
    border-color: green !important;
  }
`;

const StyledButton = styled(Button)`
  height: 3.5rem;
  width: 100%;
  background-color: ${({ theme }) => theme.main};
  font-size: 1.2rem;
  transition: background-color 0.2s;
  margin: 0;
  margin-top: ${Spacing.m};
  border-radius: ${Rounded.lg};

  &:hover {
    background-color: ${(props) => props.theme.lightMain};
  }
`;

const Wrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: ${Spacing.s};
  margin-top: ${Spacing.xl};
`;

const ResendEmailButton = styled(Button)`
  margin: 0;
  padding: 0;
  background-color: ${({ theme }) => theme.transparent};
  color: #4285f4;
  text-decoration: underline;
`;

type ForgotPasswordTokenProps = {
  nextStep: VoidFunction;
  prevStep: VoidFunction;
};

export const ForgotPasswordToken: React.FC<ForgotPasswordTokenProps> = ({
  nextStep,
  prevStep,
}) => {
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, getValues } = useFormContext<
    ForgotPasswordTokenDto & { email: SignupDto['email'] }
  >();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const onNext = async () => {
    try {
      setLoading(true);
      await dispatch(
        userActions.forgotPasswordToken({ token: getValues('token') }),
      ).unwrap();

      AtiraToast.success(t('resetpassword.token.success'));
      nextStep();
    } catch (e: any) {
      AtiraToast.apiError(e, { autoClose: 6000 });
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Wrapper>
      <Flex flexDirection="column" gap="l" width={'100%'}>
        <ForgotPasswordBackStepButton onClick={prevStep} />

        <Text>{t('resetpassword.token.title')}</Text>

        <Text color="subTextColor">
          {t('resetpassword.token.description', {
            email: truncateString(getValues('email'), 20),
          })}
        </Text>
      </Flex>

      <Controller
        name="token"
        control={control}
        rules={{ required: true, minLength: 6 }}
        render={({ field: { value, onChange }, formState: { errors } }) => (
          <InputWrapper valid={!errors.token}>
            <VerificationInput
              placeholder=""
              onChange={onChange}
              value={value}
            />
          </InputWrapper>
        )}
      />
      <StyledButton onClick={onNext} loading={loading}>
        {t('resetpassword.token.btn')}
      </StyledButton>

      <Flex justifyContent="center" alignItems="center" gap="s" marginTop="xl">
        <Text color="darkerSub">
          {t('resetpassword.token.resend.paragraph')}
        </Text>

        <ResendEmailButton>
          {t('resetpassword.token.resend.btn')}
        </ResendEmailButton>
      </Flex>
    </Wrapper>
  );
};
