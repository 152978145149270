import React, {
  ButtonHTMLAttributes,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { Images } from '../../assets';
import { LoginWithGoogleReturnPath } from '../../model/user/types/LoginWithGoogleReturnPath.enum';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { userActions } from '../../redux/user/user.slice';
import { Rounded } from '../../theme/Rounded';
import { Spacing } from '../../theme/Spacing';
import { ThemeType } from '../../theme/Theme';
import { AtiraToast } from '../../utils/AtiraToast';
import { Button } from '../Button';
import { AtiraImage } from '../Image';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: keyof ThemeType;
  backgroundColor?: keyof ThemeType;
  padding?: string;
  fontSize?: keyof typeof Spacing;
  borderRadius?: keyof typeof Rounded;
  border?: string;
  children?: React.ReactNode;
  margin?: string;
  width?: string;
  height?: string;
  loading?: boolean;
}

const StyledButton: React.FC<ButtonProps> = styled(Button)`
  background-color: ${(props) =>
    props.backgroundColor
      ? props.theme[props.backgroundColor]
      : props.theme.white};
  color: ${(props) =>
    props.color ? props.theme[props.color] : props.theme.black};
  padding: ${(props) => props.padding || `${Spacing.s} ${Spacing.l}`};
  border-radius: ${(props) => props.borderRadius || Rounded.lg};
  border: ${(props) => props.border || 'none'};
  font-size: ${(props) => props.fontSize || '1rem'};
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || '3rem'};
  margin: ${(props) => props.margin || '0'};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${Spacing.s};
  background-color: #e9f1ff;
  color: #4285f4;

  &:hover {
    background-color: #bed6ff;
  }
`;

interface GoogleButtonProps {
  color?: keyof ThemeType;
  backgroundColor?: keyof ThemeType;
  padding?: string;
  fontSize?: keyof typeof Spacing;
  borderRadius?: keyof typeof Rounded;
  border?: string;
  children?: React.ReactNode;
  margin?: string;
  width?: string;
  height?: string;
  loading?: boolean;
  title?: string;
  returnPath: LoginWithGoogleReturnPath;
  disabled?: boolean;
}

export const GoogleButton: React.FC<GoogleButtonProps> = ({
  disabled,
  ...props
}) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const loggedInUserId = useAppSelector(
    userSliceSelectors.selectLoggedInUserId,
  );
  const [isLoading, setIsLoading] = useState(false);

  const googleAuth = async () => {
    setIsLoading(true);
    try {
      const googleAuthUrl = await dispatch(
        userActions.createLoginWithGoogleLink({ returnPath: props.returnPath }),
      ).unwrap();

      window.open(googleAuthUrl, '_self');
    } catch (error: any) {
      console.log(error);
      AtiraToast.error(t('common.wrong_contact_support'));
      setIsLoading(false);
    }
  };

  const verifyAndLogUser = useCallback(
    async (code: string) => {
      try {
        const { user } = await dispatch(
          userActions.loginWithGoogle({ code, returnPath: props.returnPath }),
        ).unwrap();

        if (user) {
          setIsLoading(false);
          navigate('/dashboard', { replace: true });
        }
      } catch (error) {
        console.log(error);
        AtiraToast.error(t('common.wrong_contact_support'));
        setIsLoading(false);
      }
    },
    [dispatch, navigate, props.returnPath, t],
  );

  useEffect(() => {
    const verificationCode = searchParams.get('code');
    if (verificationCode && !loggedInUserId) {
      setIsLoading(true);
      searchParams.set('code', '');
      verifyAndLogUser(verificationCode);
    }
  }, [searchParams, verifyAndLogUser, loggedInUserId]);

  return (
    <StyledButton
      disabled={disabled === true}
      loading={isLoading}
      onClick={googleAuth}
      {...props}
    >
      <AtiraImage src={Images.GoogleIcon} width="1.5rem" />
      {props.children}
    </StyledButton>
  );
};
