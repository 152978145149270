import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Input } from '../../../components/Input';
import { Modal } from '../../../components/modal/Modal';
import { AtiraToast } from '../../../utils/AtiraToast';

const StyledInput = styled(Input)`
  width: 20rem;
  height: 3rem;
  font-size: 1.2rem;
  margin: auto;
  padding: 0 1rem;
`;

const StyledButton = styled(Button)`
  margin: auto;
  width: 8rem;
  height: 3.2rem;
  font-size: 1.2rem;
`;

interface QRDownloadModalProps {
  isOpen: boolean;
  onClose: VoidFunction;
  url: string;
}

export const QRDownloadModal: React.FC<QRDownloadModalProps> = ({
  isOpen,
  url,
  onClose,
}) => {
  const [imageName, setImageName] = useState(`AS_qr-code`);
  const [saveImageNameLoading, setSaveImageNameLoading] = useState(false);

  const { t } = useTranslation();

  const saveImage = async () => {
    try {
      setSaveImageNameLoading(true);
      const response = await (await fetch(url)).blob();
      const a = document.createElement('a');
      a.href = URL.createObjectURL(response);
      a.setAttribute('download', imageName);
      a.click();
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setSaveImageNameLoading(false);
      onClose();
    }
  };

  return (
    <Modal open={isOpen} title={t('qr.downlaod.modal.title')} onClose={onClose}>
      <Flex flexDirection="column" gap="xl">
        <StyledInput
          value={imageName}
          onChange={(e) => setImageName(e.currentTarget.value)}
        />
        <StyledButton
          onClick={() => saveImage()}
          loading={saveImageNameLoading}
        >
          {t('common.save')}
        </StyledButton>
      </Flex>
    </Modal>
  );
};
