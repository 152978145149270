import GoogleIcon from './google.svg';

export const Images = {
  AtiraLinkMainLogo: require('./atiralink-main-logo.png'),
  AtiraLinkSubLogo: require('./atiralink-sub-logo.png'),
  AtiraLinkMainIcon: require('./atiralink-main-icon.png'),
  AtiraLinkSubIcon: require('./atiralink-sub-icon.png'),
  HeroPhoto: require('./hero.jpg'),
  HeroBackground: require('./hero-bg.jpg'),
  FormTheme1: require('./form-theme1.png'),
  FormTheme2: require('./form-theme2.png'),
  FormTheme3: require('./form-theme3.png'),
  FeaturesBackground: require('./features-bg.jpg'),
  FeaturesFirstCircle: require('./hero-first-circle-img.png'),
  FeaturesSecondCircle: require('./hero-second-circle-img.png'),
  FeaturesFirstSection: require('./features-first-section.jpg'),
  FeaturesSecondSection: require('./features-second-section.jpg'),
  FeaturesNerve1: require('./features-nerve-1.jpeg'),
  FeaturesNerve2: require('./features-nerve-2.jpeg'),
  FeaturesNerve3: require('./features-nerve-3.png'),
  FeaturesSecondSection1: require('./features-second-section-1.png'),
  FeaturesSecondSection2: require('./features-second-section-2.png'),
  FeaturesSecondSection3: require('./features-second-section-3.png'),
  RegisterBackground: require('./register-bg.webp'),
  GoogleIcon,
  //
  Themes: {
    BasicFull: require('./themes/basic-full.png'),
    BasicMini: require('./themes/basic-mini.png'),
    BasicSide: require('./themes/basic-side.png'),
    UranusFull: require('./themes/uranus-full.png'),
    UranusMini: require('./themes/uranus-mini.png'),
    UranusSide: require('./themes/uranus-side.png'),
    HothFull: require('./themes/hoth-full.png'),
    HothMini: require('./themes/hoth-mini.png'),
    HothSide: require('./themes/hoth-side.png'),
    EgoFull: require('./themes/ego-full.png'),
    EgoMini: require('./themes/ego-mini.png'),
    EgoSide: require('./themes/ego-side.png'),
    EarthFull: require('./themes/earth-full.png'),
    EarthMini: require('./themes/earth-mini.png'),
    EarthSide: require('./themes/earth-side.png'),
    JupiterFull: require('./themes/jupiter-full.png'),
    JupiterMini: require('./themes/jupiter-mini.png'),
    JupiterSide: require('./themes/jupiter-side.png'),
    MarsFull: require('./themes/mars-full.png'),
    MarsMini: require('./themes/mars-mini.png'),
    MarsSide: require('./themes/mars-side.png'),
    MercuryFull: require('./themes/mercury-full.png'),
    MercuryMini: require('./themes/mercury-mini.png'),
    MercurySide: require('./themes/mercury-side.png'),
    SaturnFull: require('./themes/saturn-full.png'),
    SaturnMini: require('./themes/saturn-mini.png'),
    SaturnSide: require('./themes/saturn-side.png'),
    TheraFull: require('./themes/thera-full.png'),
    TheraMini: require('./themes/thera-mini.png'),
    TheraSide: require('./themes/thera-side.png'),
    NeptuneFull: require('./themes/neptune-full.png'),
    NeptuneMini: require('./themes/neptune-mini.png'),
    NeptuneSide: require('./themes/neptune-side.png'),
    MoonFull: require('./themes/moon-full.png'),
    MoonMini: require('./themes/moon-mini.png'),
    MoonSide: require('./themes/moon-side.png'),
    GlieseFull: require('./themes/gliese-full.png'),
    GlieseMini: require('./themes/gliese-mini.png'),
    GlieseSide: require('./themes/gliese-side.png'),
    KeplerFull: require('./themes/kepler-full.png'),
    KeplerMini: require('./themes/kepler-mini.png'),
    KeplerSide: require('./themes/kepler-side.png'),
  },
  //
};
