import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { AtiraLinkReport } from '../../../model/atira-link/AtiraLinkReport';
import { Spacing } from '../../../theme/Spacing';
import { standardDate } from '../../../utils/Date';

const Container = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.m};
  padding: 0 ${Spacing.m};
`;

const ReportWrapper = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.m};

  &:not(:last-child) {
    padding-bottom: ${Spacing.s};
    border-bottom: ${({ theme }) => `1px solid ${theme.subTextColor}`};
  }
`;

const StyledText = styled(Text)`
  font-size: 1.1rem;

  span {
    font-weight: bold;
  }
`;

type AdminReportsReportProps = {
  reports?: AtiraLinkReport[];
};

export const AdminReportsReports: React.FC<AdminReportsReportProps> = ({
  reports = [],
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'admin.reports.report',
  });

  return (
    <Container>
      {reports.map((report) => (
        <ReportWrapper>
          <StyledText>
            <span>{t('name')} </span>
            {report?.linkName}
          </StyledText>

          <StyledText>
            <span>{t('reason')} </span>
            {t(`reason.${report.reportReason.toLowerCase().replace(' ', '_')}`)}
          </StyledText>

          {report?.reportDescription ? (
            <StyledText>
              <span>{t('description')} </span>
              {report.reportDescription}
            </StyledText>
          ) : null}

          {report?.reportReason ? (
            <StyledText>
              <span>{t('date')} </span>
              {standardDate(report.createdAt)}
            </StyledText>
          ) : null}
        </ReportWrapper>
      ))}
    </Container>
  );
};
