import { Content } from 'antd/es/layout/layout';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

import { Images } from '../../assets';
import { AtiraColumn } from '../../components/AtiraColumn';
import { AtiraRow } from '../../components/AtiraRow';
import { Button } from '../../components/Button';
import { Flex } from '../../components/Flex';
import { AtiraImage } from '../../components/Image';
import { Text } from '../../components/Text';
import { Breakpoints } from '../../theme/Breakpoints';
import { Rounded } from '../../theme/Rounded';
import { Spacing } from '../../theme/Spacing';

const StyledContent = styled(Content)`
  background: linear-gradient(
    90deg,
    rgba(35, 40, 147, 1) 41%,
    rgba(151, 11, 170, 1) 76%
  );
`;

const HeroCircleAnimation = keyframes`
  from {
    transform: translateY(-1rem);
  } to {
    transform: translateY(1rem);
  }
`;

const CallToAction = styled(Button)`
  color: ${(props) => props.theme.main};
  background-color: ${(props) => props.theme.light};
  padding: 0.75rem 1rem;
  width: 20rem;

  @media (min-width: ${Breakpoints.TABLET}) {
    font-size: 1.25rem;
  }
`;

const FeatureWrapper = styled(Flex)`
  gap: ${Spacing.m};
  align-items: center;

  @media (min-width: ${Breakpoints.TABLET}) {
    align-items: flex-start;
    flex-direction: column;
  }
`;

const HeroImageWrapper = styled(Flex)`
  position: relative;
  justify-content: center;
`;

const HeroImageLeftCircle = styled(Flex)`
  height: 4rem;
  width: 4rem;
  position: absolute;
  bottom: 2rem;
  left: 1rem;
  border-radius: ${Rounded.circle};
  background-size: 20rem;
  padding: ${Spacing.m};
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  z-index: 111;
  animation: ${HeroCircleAnimation} infinite ease-in-out alternate 3s;
  @media (min-width: ${Breakpoints.TABLET}) {
    height: 6rem;
    width: 6rem;
    bottom: 1.5rem;
  }
`;

const HeroImageRightCircle = styled(Flex)`
  height: 5rem;
  width: 5rem;
  background-color: ${(props) => props.theme.darkMain};
  position: absolute;
  bottom: 1.5rem;
  right: 1rem;
  border-radius: ${Rounded.circle};
  padding: ${Spacing.m};
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  animation: ${HeroCircleAnimation} infinite ease-in-out alternate 6s;
  @media (min-width: ${Breakpoints.TABLET}) {
    height: 8rem;
    width: 8rem;
    bottom: 1rem;
  }
`;

const Section = styled(Flex)`
  padding: 0 ${Spacing.l};

  @media (min-width: ${Breakpoints.TABLET}) {
    padding: 0 ${Spacing.xxxl};
  }
`;

const FirstSectionMobileImageWrapper = styled(Flex)`
  @media (min-width: ${Breakpoints.DESKTOP}) {
    display: none;
  }
`;

export const LandingPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <StyledContent>
      <Section marginTop="m">
        <AtiraRow justify={'space-around'} align={'middle'} gutter={[10, 30]}>
          <AtiraColumn xs={24} sm={24} md={24} lg={9} xl={9}>
            <Flex flexDirection="column" gap="m">
              <Text fontSize="_8xl" fontWeight={'bold'} color="light">
                {t('app.name').toUpperCase()}
              </Text>
              <Text fontSize="xxxl" fontWeight={'bold'} color="light">
                {t('app.description')}
              </Text>
              <Text fontSize="xl" color="light" lineHeight={'2.2rem'}>
                {t('front_page.hero.paragraph')}
              </Text>
              <CallToAction onClick={() => navigate('/signup')}>
                {t('front_page.hero.main_c2a')}
              </CallToAction>
            </Flex>
          </AtiraColumn>

          <AtiraColumn xs={24} sm={24} md={24} lg={12} xl={11}>
            <HeroImageWrapper>
              <HeroImageLeftCircle>
                <AtiraImage src={Images.FeaturesFirstCircle} />
              </HeroImageLeftCircle>
              <AtiraImage
                src={Images.FeaturesNerve1}
                width="50%"
                style={{
                  left: '0rem',
                  position: 'absolute',
                  transform: 'rotate(-4deg)',
                  borderRadius: '2rem',
                }}
              />
              <AtiraImage
                src={Images.FeaturesNerve2}
                width="50%"
                style={{
                  transform: 'rotate(4deg)',
                  borderRadius: '2rem',
                }}
              />
              <HeroImageRightCircle>
                <AtiraImage src={Images.FeaturesSecondCircle} />
              </HeroImageRightCircle>
            </HeroImageWrapper>
          </AtiraColumn>
        </AtiraRow>
      </Section>

      <Section paddingTop="xxxl" paddingBottom="xxxl">
        <AtiraRow justify={'space-around'} gutter={[10, 30]}>
          <AtiraColumn
            xs={0}
            sm={0}
            md={0}
            lg={{ span: 12, order: 1 }}
            xl={{ span: 9, order: 1 }}
          >
            <Flex paddingTop="xl">
              <AtiraImage src={Images.FeaturesNerve3} width="100%" />
            </Flex>
          </AtiraColumn>

          <AtiraColumn
            xs={{ span: 24, order: 1 }}
            sm={{ span: 24, order: 1 }}
            md={{ span: 24, order: 1 }}
            lg={{ span: 11, order: 2 }}
            xl={{ span: 11, order: 2 }}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Flex flexDirection="column" gap="m">
              <Text fontSize="xxl" fontWeight={'bolder'} color="light">
                {t('front_page.section.1.title')}
              </Text>
              <Text fontSize="xl" color="light">
                {t('front_page.section.1.paragraph')}
              </Text>

              <FirstSectionMobileImageWrapper>
                <AtiraImage src={Images.FeaturesNerve3} width="100%" />
              </FirstSectionMobileImageWrapper>
              <AtiraRow gutter={[10, 0]}>
                <AtiraColumn
                  xs={24}
                  sm={24}
                  md={24}
                  lg={8}
                  xl={8}
                  style={{ marginTop: '2rem' }}
                >
                  <FeatureWrapper>
                    <AtiraImage
                      src={Images.FeaturesSecondSection1}
                      width="45%"
                    />
                    <Text fontSize="xxl" color="light">
                      {t('front_page.section.1.feature.1.title')}
                    </Text>
                    <Text color="light" fontSize="l">
                      {t('front_page.section.1.feature.1.description')}
                    </Text>
                  </FeatureWrapper>
                </AtiraColumn>
                <AtiraColumn
                  xs={24}
                  sm={24}
                  md={24}
                  lg={8}
                  xl={8}
                  style={{ marginTop: '2rem' }}
                >
                  <FeatureWrapper>
                    <AtiraImage
                      src={Images.FeaturesSecondSection2}
                      width="45%"
                    />
                    <Text fontSize="xxl" color="light">
                      {t('front_page.section.1.feature.2.title')}
                    </Text>
                    <Text color="light" fontSize="l">
                      {t('front_page.section.1.feature.1.description')}
                    </Text>
                  </FeatureWrapper>
                </AtiraColumn>
                <AtiraColumn
                  xs={24}
                  sm={24}
                  md={24}
                  lg={8}
                  xl={8}
                  style={{ marginTop: '2rem' }}
                >
                  <FeatureWrapper>
                    <AtiraImage
                      src={Images.FeaturesSecondSection3}
                      width="45%"
                    />
                    <Text fontSize="xxl" color="light">
                      {t('front_page.section.1.feature.3.title')}
                    </Text>
                    <Text color="light" fontSize="l">
                      {t('front_page.section.1.feature.1.description')}
                    </Text>
                  </FeatureWrapper>
                </AtiraColumn>
              </AtiraRow>
            </Flex>
          </AtiraColumn>
        </AtiraRow>
      </Section>
    </StyledContent>
  );
};
