import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

const adminState = (state: RootState) => state;

const selectAdminStats = createSelector(
  adminState,
  (state) => state.admin.stats,
);

const selectUsers = createSelector(
  adminState,
  (state) => state.admin.searchUsers.result || [],
);

const searchUsersLoading = createSelector(
  adminState,
  (state) => state.admin.searchUsersLoading,
);

const selectReports = createSelector(
  adminState,
  (state) => state.admin.reports,
);

const selectReportsLoading = createSelector(
  adminState,
  (state) => state.admin.reportsLoading,
);

const selectReportsMeta = createSelector(
  adminState,
  (state) => state.admin.reportsMeta || { count: 10, page: 0 },
);

export const adminSliceSelectors = {
  selectAdminStats,
  selectUsers,
  searchUsersLoading,
  selectReports,
  selectReportsMeta,
  selectReportsLoading,
};
