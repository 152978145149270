import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { Header } from 'antd/es/layout/layout';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Images } from '../assets';
import { AtiraColumn } from '../components/AtiraColumn';
import { AtiraIcon } from '../components/AtiraIcon';
import { AtiraRow } from '../components/AtiraRow';
import { Button } from '../components/Button';
import { Drawer } from '../components/Drawer';
import { Flex } from '../components/Flex';
import { AtiraImage } from '../components/Image';
import { LanguageSwitcher } from '../components/LanguageSwitcher';
import { Breakpoints } from '../theme/Breakpoints';
import { Rounded } from '../theme/Rounded';
import { Spacing } from '../theme/Spacing';

const StyledHeader = styled(Header)<{ onRegisterPages: boolean }>`
  width: 100%;
  background-image: ${({ onRegisterPages, theme }) =>
    `linear-gradient(
    90deg, ${onRegisterPages ? theme.main : 'rgb(151, 11, 170) 0%'}
    ,${onRegisterPages ? theme.main : 'rgb(35, 40, 147) 99%'}
  )`};
  position: sticky;
  top: 0rem;
  z-index: 999;

  height: fit-content;
  padding: ${Spacing.s};
  line-height: normal;
`;

const HeaderLink = styled(Link)`
  color: ${(props) => props.theme.light};
  text-decoration: none;
  border-radius: ${Rounded.sm};
  padding: ${Spacing.s};

  transition: all 0.3s;
  &:hover {
    background-color: ${(props) => props.theme.sub};
    color: ${(props) => props.theme.main};
  }
`;
const HeaderLinksSection = styled(AtiraColumn)`
  display: flex;

  @media (max-width: ${Breakpoints.DESKTOP}) {
    display: none;
  }
`;

const StartButton = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.main};
  padding: ${Spacing.s} ${Spacing.m};
  border-radius: ${Rounded.pill};
  background-color: ${(props) => props.theme.light};
  font-size: 0.75rem;
  transition: all 0.3s;
  &:hover {
    background-color: ${(props) => props.theme.sub};
    color: ${(props) => props.theme.dark};
  }
  @media (min-width: ${Breakpoints.TABLET}) {
    font-size: 1.1rem;
  }
`;

const DrawerButton = styled(AtiraColumn)`
  @media (min-width: ${Breakpoints.DESKTOP}) {
    display: none;
  }

  @media (max-width: ${Breakpoints.TABLET}) {
    display: inline-block;
  }
`;

const HeaderLogoWrapper = styled(Flex)`
  justify-content: center;
  @media (min-width: ${Breakpoints.DESKTOP}) {
    justify-content: flex-start;
  }
`;

const DrawerLink = styled(Link)`
  font-size: 1.5rem;
  color: inherit;
  &:hover {
    color: inherit;
  }
`;

const StyledDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    box-shadow: none !important;
  }
  .ant-drawer-title {
    font-size: 1.7rem;
  }
  .ant-drawer-header-title {
    flex-direction: row-reverse;
    justify-content: space-between;
    font-size: 1rem;
  }
  .anticon-close svg {
    background-color: ${({ theme }) => theme.danger};
    opacity: 0.7;
    height: 1.8rem;
    width: 1.8rem;
    padding: 0.2rem;
    border-radius: ${Rounded.sm};
    transition: all 0.2s;
  }
  .anticon-close svg:hover {
    opacity: 1;
  }
  .anticon-close svg path {
    color: ${({ theme }) => theme.dark};
  }
  * {
    color: ${({ theme }) => theme.light};
  }
`;

const drawerStyle = {
  background:
    'linear-gradient(90deg, rgba(14, 5, 138, 1) 40%, rgba(255, 255, 255, 0) 100%)',
};

export const PublicHeader: React.FC = () => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const location = useLocation();

  const onRegisterPages =
    location.pathname.includes('forgot-password') ||
    location.pathname.includes('login') ||
    location.pathname.includes('signup');

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <StyledHeader onRegisterPages={onRegisterPages}>
      <AtiraRow gutter={[0, 20]} align={'middle'}>
        <AtiraColumn
          xs={onRegisterPages ? 5 : 0}
          sm={onRegisterPages ? 5 : 0}
          md={onRegisterPages ? 5 : 0}
          lg={0}
          xl={0}
        >
          <DrawerButton>
            <Flex justifyContent="center">
              <Button icon={faBars} onClick={showDrawer} />
            </Flex>
          </DrawerButton>
        </AtiraColumn>

        <AtiraColumn
          xs={onRegisterPages ? 13 : 24}
          sm={onRegisterPages ? 13 : 24}
          md={onRegisterPages ? 13 : 24}
          lg={6}
          xl={5}
        >
          <HeaderLogoWrapper>
            <AtiraImage
              src={Images.AtiraLinkSubLogo}
              style={{ maxWidth: '20rem' }}
            />
          </HeaderLogoWrapper>
        </AtiraColumn>

        <AtiraColumn
          xs={onRegisterPages ? 6 : 0}
          sm={onRegisterPages ? 6 : 0}
          md={onRegisterPages ? 6 : 0}
          lg={0}
          xl={0}
        >
          <Flex justifyContent="flex-end">
            <LanguageSwitcher />
          </Flex>
        </AtiraColumn>

        <HeaderLinksSection xs={0} sm={0} md={12} lg={8} xl={12}>
          <Flex
            gap="l"
            alignItems="center"
            justifyContent="center"
            width={'100%'}
          >
            <HeaderLink to={''}>{t('common.how_to')}</HeaderLink>
            <HeaderLink to={''}>{t('common.templates')}</HeaderLink>
            <HeaderLink to={''}>{t('common.pricing')}</HeaderLink>
            <HeaderLink to={''}>{t('common.help')}</HeaderLink>
          </Flex>
        </HeaderLinksSection>

        <AtiraColumn
          xs={onRegisterPages ? 0 : 24}
          sm={onRegisterPages ? 0 : 24}
          md={onRegisterPages ? 0 : 24}
          lg={10}
          xl={7}
        >
          <AtiraRow align={'middle'} style={{ height: '100%' }}>
            <DrawerButton xs={6} sm={6} md={6} lg={8} xl={8}>
              <Flex justifyContent="center">
                <Button icon={faBars} onClick={showDrawer} />
              </Flex>
            </DrawerButton>
            <AtiraColumn xs={6} sm={6} md={6} lg={8} xl={8}>
              <Flex justifyContent="center">
                <LanguageSwitcher />
              </Flex>
            </AtiraColumn>

            <AtiraColumn xs={6} sm={6} md={6} lg={8} xl={8}>
              <Flex justifyContent="center">
                <StartButton to={'/login'}>{t('common.login')}</StartButton>
              </Flex>
            </AtiraColumn>

            <AtiraColumn xs={6} sm={6} md={6} lg={8} xl={8}>
              <Flex justifyContent="center">
                <StartButton to={'/signup'}>{t('common.signup')}</StartButton>
              </Flex>
            </AtiraColumn>
          </AtiraRow>
        </AtiraColumn>
      </AtiraRow>

      <StyledDrawer
        title="Basic Drawer"
        onClose={onClose}
        open={open}
        placement="left"
        style={drawerStyle}
      >
        <Flex flexDirection="column" gap="m">
          <Flex gap="s" alignItems="center">
            <AtiraIcon icon={faCircle} color="light" size="xs" />

            <DrawerLink to={''}>{t('common.how_to')}</DrawerLink>
          </Flex>

          <Flex gap="s" alignItems="center">
            <AtiraIcon icon={faCircle} color="light" size="xs" />

            <DrawerLink to={''}>{t('common.templates')}</DrawerLink>
          </Flex>

          <Flex gap="s" alignItems="center">
            <AtiraIcon icon={faCircle} color="light" size="xs" />

            <DrawerLink to={''}>{t('common.pricing')}</DrawerLink>
          </Flex>

          <Flex gap="s" alignItems="center">
            <AtiraIcon icon={faCircle} color="light" size="xs" />

            <DrawerLink to={''}>{t('common.help')}</DrawerLink>
          </Flex>
        </Flex>
      </StyledDrawer>
    </StyledHeader>
  );
};
