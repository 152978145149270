import React, { ReactNode, createContext, useContext, useState } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import { DarkTheme, LightTheme, ThemeType } from './theme/Theme';

export enum Themes {
  LIGHT = 'LIGHT',
  DARK = 'DARK',
}

interface ThemeContextType {
  toggleTheme: VoidFunction;
  theme: ThemeType;
  currentTheme: Themes;
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};

interface ThemeProviderProps {
  children: ReactNode;
}

const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const savedTheme = window.localStorage.getItem('theme');

  const [theme, setTheme] = useState<ThemeType>(() => getInitialTheme());
  const [currentTheme, setCurrentTheme] = useState<Themes>(
    savedTheme === Themes.DARK ? Themes.DARK : Themes.LIGHT,
  );

  function getInitialTheme(): ThemeType {
    if (savedTheme === Themes.DARK) {
      return DarkTheme;
    } else if (savedTheme === Themes.LIGHT) {
      return LightTheme;
    }
    return LightTheme;
  }

  function toggleTheme() {
    const newTheme = theme.main === LightTheme.main ? DarkTheme : LightTheme;
    const newCurrentTheme =
      currentTheme === Themes.LIGHT ? Themes.DARK : Themes.LIGHT;

    setTheme(newTheme);
    setCurrentTheme(newCurrentTheme);
    window.localStorage.setItem('theme', newCurrentTheme);
  }

  return (
    <ThemeContext.Provider value={{ toggleTheme, theme, currentTheme }}>
      <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
