import { t } from 'i18next';

import { AtiraRoute } from '../AtiraRoute';
import { AtiraRouteAdmin } from '../AtiraRouteAdmin';
import { Layout } from '../Layout';
import { QRRoute } from '../QR-generator/QRRoute';
import { AdminRoute } from '../admin/AdminRoute';
import { Dashboard } from '../dashbord/Dashbord';
import { AtiraLinkRoute } from '../my-link/AtiraLinkRoute';
import { URLRoute } from '../url/URLRoute';

export const PrivateRouter = [
  {
    element: <Layout />,
    errorElement: <div>{t('common.error.back_home')}</div>,
    children: [
      {
        path: '/dashboard',
        element: <AtiraRoute component={<Dashboard />} />,
      },
      {
        path: '/my-link',
        element: <AtiraRoute component={<AtiraLinkRoute />} />,
      },
      {
        path: '/my-urls',
        element: <AtiraRoute component={<URLRoute />} />,
      },
      {
        path: '/my-qrs',
        element: <AtiraRoute component={<QRRoute />} />,
      },
      {
        path: '/admin',
        element: <AtiraRouteAdmin component={<AdminRoute />} />,
      },
    ],
  },
];
