import debounce from 'lodash/debounce';
import React, { ChangeEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex } from '../../components/Flex';
import { Input } from '../../components/Input';
import { Spinner } from '../../components/Spinner';
import { SearchUsersUserDto } from '../../model/admin/dto/SearchUsersUserDto';
import { adminSliceSelectors } from '../../redux/admin/admin.selector';
import { adminActions } from '../../redux/admin/admin.slice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { AtiraToast } from '../../utils/AtiraToast';
import { AdminSearchUserRow } from './components/AdminSearchUserRow';
import { AdminSearchUsersDetailsModal } from './components/AdminSearchUsersDetailsModal';

export const AdminSearchUsersTab: React.FC = () => {
  const [keyword, setKeyword] = useState('');
  const [userDetailsModalVisible, setUserDetailsModalVisible] = useState(false);
  const [user, setUser] = useState<SearchUsersUserDto | null>(null);

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;

  const users = useAppSelector(adminSliceSelectors.selectUsers);

  const searchLoading = useAppSelector(adminSliceSelectors.searchUsersLoading);

  const fetchUsers = useCallback(
    (keyword: string) => {
      dispatch(adminActions.searchUsers({ userId, keyword }))
        .unwrap()
        .catch((error) => AtiraToast.apiError(error));
    },
    [dispatch, userId],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(async (keyword: string) => fetchUsers(keyword), 800),
    [fetchUsers],
  );

  const onSearch = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setKeyword(target.value);
    debouncedSearch(target.value);
  };

  return (
    <Flex flexDirection="column" flex={1}>
      <Flex flexDirection="column" gap="m" flex={1}>
        <Input
          width="15rem"
          value={keyword}
          onChange={onSearch}
          placeholder={t('common.search')}
        />

        <Flex flexDirection="column" gap="s" flex={1}>
          {!searchLoading ? (
            users.map((user) => (
              <AdminSearchUserRow
                key={user.user?._id}
                setUser={setUser}
                user={user}
                setDetailsModalVisible={setUserDetailsModalVisible}
              />
            ))
          ) : (
            <Spinner size="3rem" />
          )}
        </Flex>
      </Flex>

      <AdminSearchUsersDetailsModal
        isOpen={userDetailsModalVisible}
        onClose={() => setUserDetailsModalVisible(false)}
        user={user}
      />
    </Flex>
  );
};
