import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faTiktok } from '@fortawesome/free-brands-svg-icons/faTiktok';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons/faXTwitter';
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { Drawer } from '../../../components/Drawer';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { atiraLinkSliceSelectors } from '../../../redux/nerve/atira-link.selector';
import { useAppSelector } from '../../../redux/store';

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.black};
  font-size: 1.2rem;
  margin: 0;
  background-color: ${({ theme }) => theme.transparent};
  padding: 0;

  &:hover {
    color: ${({ theme }) => theme.darkerSub};
  }
`;

type ShareDrawerProps = {
  open: boolean;
  onClose: VoidFunction;
};

const socialMediaLinks = [
  {
    id: 'instagram',
    name: 'Instagram',
    icon: faInstagram,
    url: 'https://www.instagram.com/accounts/edit/',
  },
  {
    id: 'twitter',
    name: 'Twitter',
    icon: faXTwitter,
    url: 'https://twitter.com/settings/profile',
  },
  {
    id: 'facebook',
    name: 'Facebook',
    icon: faFacebook,
    url: 'https://www.facebook.com/profile.php?sk=editprofile',
  },
  {
    id: 'youtube',
    name: 'YouTube',
    icon: faYoutube,
    url: 'https://www.youtube.com/account',
  },
  {
    id: 'tiktok',
    name: 'TikTok',
    icon: faTiktok,
    url: 'https://www.tiktok.com/settings',
  },
];

export const ShareDrawer: React.FC<ShareDrawerProps> = ({ open, onClose }) => {
  const { t } = useTranslation();

  const userLink = useAppSelector(atiraLinkSliceSelectors.selectUserAtiraLink);
  const userLinkUrl = `https://atrl.ink/${userLink?.linkName}`;

  const copyLink = async (url: string) => {
    try {
      await navigator.clipboard.writeText(userLinkUrl);

      window.open(url, '_blank');
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Drawer
      title={t('header.share.drawer.title')}
      onClose={onClose}
      open={open}
      width="30rem"
    >
      <Flex gap="l" flexDirection="column">
        <Text color="main" fontSize="l">
          {t('header.share.drawer.paragraph')}
        </Text>
        <Flex gap="l" justifyContent="center">
          {socialMediaLinks.map((social) => (
            <StyledButton
              key={social.name}
              onClick={() => copyLink(social.url)}
              icon={social.icon}
              iconWidth="2x"
            />
          ))}
        </Flex>
      </Flex>
    </Drawer>
  );
};
