import { Image } from 'antd';
import capitalize from 'lodash/capitalize';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Images } from '../../../assets';
import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { CreateAtiraLinkDto } from '../../../model/atira-link/dto/CreateAtiraLinkDto';
import { AtiraLinkThemes } from '../../../model/atira-link/types/AtiraLinkThemes.enum';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';

const iterableThemes: Record<
  AtiraLinkThemes,
  Record<string, string>
> = Object.entries(AtiraLinkThemes).reduce(
  (acc, [key, value]) => {
    const themeKey = key as AtiraLinkThemes;

    acc[themeKey] = {
      mini: Images.Themes[
        `${capitalize(themeKey)}Mini` as keyof typeof Images.Themes
      ],
      full: Images.Themes[
        `${capitalize(themeKey)}Full` as keyof typeof Images.Themes
      ],
      side: Images.Themes[
        `${capitalize(themeKey)}Side` as keyof typeof Images.Themes
      ],
    };

    return acc;
  },
  {} as Record<AtiraLinkThemes, Record<string, string>>,
);

const SectionWrapper = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.s};
  width: 100%;
`;

const Section = styled(Flex)`
  background-color: ${({ theme }) => theme.light};
  border-radius: ${Rounded.lg};
  padding: ${Spacing.m};
  gap: ${Spacing.m};
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

const CustomTemplate = styled(Flex)`
  background-color: ${({ theme }) => theme.darkerSub};
  border-radius: ${Rounded.xl};
  width: 10rem;
  height: 17rem;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const StyledButton = styled(Button)<{ selected: boolean }>`
  background-color: ${({ theme, selected }) =>
    selected ? theme.main : theme.darkSub};
  color: ${({ theme, selected }) =>
    selected ? theme.light : theme.lightTextColor};
`;

export const AtiraLinkTemplatesTab: React.FC = () => {
  const { t } = useTranslation();
  const { setValue } = useFormContext<CreateAtiraLinkDto>();
  const theme = useWatch({ name: 'theme' });

  return (
    <Flex width={'100%'} gap="l">
      <SectionWrapper>
        <Text fontWeight="bold" fontSize="xm" color="textColor">
          {t('common.templates')}
        </Text>
        <Text>({t('atira_link.create.templates.template_title')})</Text>

        <Section>
          {Object.entries(iterableThemes).map(([key, value]) => (
            <Flex
              flexDirection="column"
              alignItems="center"
              gap="s"
              width="fit-content"
              key={key}
            >
              <CustomTemplate>
                <Image.PreviewGroup>
                  <Image src={value.mini} alt="mini" />
                  <Image
                    style={{ display: 'none' }}
                    src={value.full}
                    alt="full"
                  />
                  <Image
                    style={{ display: 'none' }}
                    src={value.side}
                    alt="side"
                  />
                </Image.PreviewGroup>
              </CustomTemplate>
              <StyledButton
                onClick={() => setValue('theme', key as AtiraLinkThemes)}
                selected={theme === key}
              >
                {key.replaceAll('_', ' ')}
                {theme === key ? ' ✓' : ''}
              </StyledButton>
            </Flex>
          ))}
        </Section>
      </SectionWrapper>
    </Flex>
  );
};
