import { faCircleUser } from '@fortawesome/free-solid-svg-icons/faCircleUser';
import isString from 'lodash/isString';
import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { AtiraIcon } from '../../../components/AtiraIcon';
import i18n, { AppLangs } from '../../../i18n';
import { AtiraLink } from '../../../model/atira-link/AtiraLink';
import { getMediaUrl } from '../../../utils/Media';
import { AtiraLinkLeftSide } from './partials/AtiraLinkLeftSide';
import { AtiraLinkRightSide } from './partials/AtiraLinkRightSide';
import { AtiraLinkSideMenu } from './partials/AtiraLinkSideMenu';

const PhotoWrapper = styled.div`
  svg {
    width: 100%;
    height: 100%;
  }
`;

export const AtiraLinkPreviewPhoneEJS: React.FC = () => {
  const previewBodyRef = useRef<HTMLBodyElement>(null);
  const previewSidemenuRef = useRef<HTMLDivElement>(null);

  const { watch } = useFormContext<AtiraLink>();
  const atiraLink = watch();

  const openDrawer = () => {
    if (previewBodyRef.current) {
      previewBodyRef.current.classList.toggle('overflow-y-auto');
      previewBodyRef.current.classList.toggle('overflow-x-hidden');
    }

    if (previewSidemenuRef.current) {
      previewSidemenuRef.current.style.visibility = 'visible';

      if (previewSidemenuRef.current.style.transform.includes('100%')) {
        previewSidemenuRef.current.style.transform = 'translateX(0)';
        previewSidemenuRef.current.setAttribute('aria-hidden', 'false');
        return;
      }

      previewSidemenuRef.current.setAttribute('aria-hidden', 'true');
      previewSidemenuRef.current.style.transform = 'translateX(100%)';
    }
  };

  return (
    <div>
      <Helmet>
        <link
          href={`https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css`}
          rel="stylesheet"
          integrity="sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH"
          crossOrigin="anonymous"
        />

        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.6.0/css/all.min.css"
          integrity="sha512-Kc323vGBEqzTmouAECnVceyQqyqdsSiqLQISBL29aUW4U/M7pSPA/gEUZQqv1cwx4OnYxTxve5UMg5GT6L4JJg=="
          crossOrigin="anonymous"
          referrerPolicy="no-referrer"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href={`/style/${atiraLink.theme?.toLowerCase() || 'basic'}.css`}
        />
      </Helmet>
      <body
        ref={previewBodyRef}
        className={`bg-white position-relative overflow-x-hidden overflow-y-auto`}
        style={{ maxHeight: '60vh' }}
      >
        <header className="py-3 shadow-sm sticky-top zindex-5 bg-white">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12">
                <div
                  className={`d-flex ${i18n.language === AppLangs.AR ? 'flex-row-reverse' : 'flex-row'}`}
                >
                  <div
                    className={`d-flex w-75 align-items-center gap-3 ${i18n.language === AppLangs.AR ? 'flex-row-reverse' : ''}`}
                  >
                    {atiraLink.image ? (
                      <img
                        src={
                          isString(atiraLink.image)
                            ? getMediaUrl(atiraLink.image!)
                            : URL.createObjectURL(atiraLink.image!)
                        }
                        className="header-photo object-fit-cover"
                        alt={atiraLink.title}
                      />
                    ) : (
                      <PhotoWrapper className="header-photo">
                        <AtiraIcon icon={faCircleUser} />
                      </PhotoWrapper>
                    )}
                    <span className="header-title text-truncate">
                      {atiraLink.title}
                    </span>
                  </div>
                  <button
                    className={`navbar-toggler ms-auto`}
                    onClick={openDrawer}
                  >
                    <i className="fa-solid fa-bars fs-5"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </header>

        <main className="atira-section py-5 d-flex align-items-center justify-content-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 gy-3">
                <AtiraLinkLeftSide atiraLink={atiraLink} />
              </div>
              {atiraLink.socials?.length ? (
                <AtiraLinkRightSide socials={atiraLink.socials} />
              ) : null}
            </div>
          </div>
        </main>

        <footer className="py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="text-center">
                  <a
                    href="https://www.atiralink.com"
                    className="footer-link py-1 px-2 text-decoration-none"
                  >
                    Get Your Link @ atiralink.com
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>

        <AtiraLinkSideMenu
          ref={previewSidemenuRef}
          title={atiraLink.title}
          linkName={atiraLink.linkName}
          onClose={openDrawer}
        />

        <script
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.bundle.min.js"
          integrity="sha384-YvpcrYf0tY3lHB60NNkmXc5s9fDVZLESaAA55NDzOxhy9GkcIdslK1eN7N6jIeHz"
          crossOrigin="anonymous"
          defer
        ></script>
        <script src="/public/atira-link/script.js" defer></script>
      </body>
    </div>
  );
};
