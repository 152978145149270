export enum AtiraLinkThemes {
  BASIC = 'BASIC',
  URANUS = 'URANUS',
  HOTH = 'HOTH',
  EGO = 'EGO',
  EARTH = 'EARTH',
  MERCURY = 'MERCURY',
  JUPITER = 'JUPITER',
  MARS = 'MARS',
  NEPTUNE = 'NEPTUNE',
  SATURN = 'SATURN',
  THERA = 'THERA',
  MOON = 'MOON',
  GLIESE = 'GLIESE',
  KEPLER = 'KEPLER',
}
