import { createSlice } from '@reduxjs/toolkit';

import { AdminAxios } from '../../axios/admin/admin.axios';
import { AdminStats } from '../../model/admin/AdminStats';
import { GetAdminStatsDto } from '../../model/admin/dto/GetAdminStatsDto';
import { GetReportsResponseDto } from '../../model/admin/dto/GetReportResponseDto';
import { GetReportsDto } from '../../model/admin/dto/GetReportsDto';
import { SearchUsersDto } from '../../model/admin/dto/SearchUsersDto';
import { SearchUsersResponseDto } from '../../model/admin/dto/SearchUsersResponseDto';
import { AtiraThunk } from '../AtiraThunk';
import { userActions } from '../user/user.slice';

interface AdminReducer {
  stats: AdminStats;
  searchUsers: SearchUsersResponseDto;
  searchUsersLoading: boolean;
  reports: GetReportsResponseDto['data'];
  reportsLoading: boolean;
  reportsMeta: GetReportsResponseDto['meta'];
}

const initialState = Object.freeze<AdminReducer>({
  stats: {
    users: { lastMonth: 0, lastWeek: 0, total: 0 },
    atiraLinks: { lastMonth: 0, lastWeek: 0, total: 0 },
    QRs: { lastMonth: 0, lastWeek: 0, total: 0 },
    shortURLs: { lastMonth: 0, lastWeek: 0, total: 0 },
  },
  searchUsers: { result: [] },
  searchUsersLoading: false,
  reportsMeta: { count: 10, page: 0 },
  reports: [],
  reportsLoading: false,
});

const getAdminStats = AtiraThunk<AdminStats, GetAdminStatsDto>(
  '/admin/stats',
  (dto) => AdminAxios.getAdminStats(dto),
);

const searchUsers = AtiraThunk<SearchUsersResponseDto, SearchUsersDto>(
  '/admin/user/search',
  (dto) => AdminAxios.searchUsers(dto),
);

const getReports = AtiraThunk<GetReportsResponseDto, GetReportsDto>(
  '/report',
  (dto) => AdminAxios.getReports(dto),
);

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {},
  extraReducers: ({ addCase }) => {
    addCase(getAdminStats.fulfilled, (state, action) => {
      state.stats = action.payload;
    });

    addCase(searchUsers.pending, (state) => {
      state.searchUsersLoading = true;
    });

    addCase(searchUsers.fulfilled, (state, action) => {
      state.searchUsers.result = action.payload.result || [];
      state.searchUsersLoading = false;
    });

    addCase(searchUsers.rejected, (state) => {
      state.searchUsersLoading = false;
    });

    addCase(getReports.pending, (state) => {
      state.reportsLoading = true;
    });

    addCase(getReports.rejected, (state) => {
      state.reportsLoading = false;
    });

    addCase(getReports.fulfilled, (state, action) => {
      state.reportsLoading = false;
      state.reportsMeta = action.payload.meta;
      state.reports = action.payload.data || [];
    });

    addCase(userActions.logout.fulfilled, (state) => {
      return (state = initialState);
    });
  },
});

export const adminActions = {
  getAdminStats,
  searchUsers,
  getReports,
};

export default adminSlice.reducer;
