import { AtiraRouteNotLoggedIn } from '../AtiraRouteNotLoggedIn';
import { PublicLayout } from '../PublicLayout';
import { LandingPage } from '../landing-page/LandingPage';
import { Login } from '../loginSignup/Login';
import { ForgotPassword } from '../loginSignup/forgot-password/ForgotPassword';
import { Signup } from '../loginSignup/signup/Signup';

export const PublicRouter = [
  {
    element: <PublicLayout />,
    errorElement: <div>error</div>,
    children: [
      {
        path: '/',
        element: <AtiraRouteNotLoggedIn component={<LandingPage />} />,
      },
      {
        path: '/login',
        element: <AtiraRouteNotLoggedIn component={<Login />} />,
      },
      {
        path: '/signup',
        element: <AtiraRouteNotLoggedIn component={<Signup />} />,
      },
      {
        path: '/forgot-password',
        element: <AtiraRouteNotLoggedIn component={<ForgotPassword />} />,
      },
    ],
  },
];
