import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import weekday from 'dayjs/plugin/weekday';
import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import styled from 'styled-components';

import { GlobalStyle } from './GlobalStyle';
import ThemeProvider from './ThemeContext';
import { onAppStart } from './axios/onAppStart';
import { Flex } from './components/Flex';
import store from './redux/store';
import router from './routes/router/Router';
import { AntdConfig } from './theme/AntdConfig';

dayjs.extend(weekday);
dayjs.extend(localizedFormat);
dayjs.extend(localeData);

const consoleErr = console.error;
const SUPPRESSED_WARNINGS = ['Warning: React does not recognize the'];

console.error = function filterWarnings(msg, ...args) {
  if (
    typeof msg === 'string' &&
    !SUPPRESSED_WARNINGS.some((entry) => msg.includes(entry))
  ) {
    consoleErr(msg, ...args);
  }
};

const RootContainer = styled(Flex)`
  flex-direction: column;
  height: 100%;
  min-height: 100%;
`;

function App() {
  // if (process.env.NODE_ENV === 'production') {
  //   console.log = () => {};
  //   console.error = () => {};
  //   console.debug = () => {};
  // }

  useEffect(() => {
    onAppStart();
  }, []);

  return (
    <ErrorBoundary
      fallbackRender={(e) => {
        console.log(e);
        return (
          <div>
            <p>SOME ERORR</p>
          </div>
        );
      }}
    >
      <GlobalStyle />
      <Provider store={store.store}>
        <PersistGate persistor={store.persistor} loading={null}>
          <ThemeProvider>
            <RootContainer>
              <AntdConfig>
                <RouterProvider router={router} />
              </AntdConfig>
            </RootContainer>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  );
}

export default App;
