import { faCircleUser } from '@fortawesome/free-solid-svg-icons/faCircleUser';
import isString from 'lodash/isString';
import React from 'react';
import styled from 'styled-components';

import { AtiraIcon } from '../../../../components/AtiraIcon';
import { AtiraLink } from '../../../../model/atira-link/AtiraLink';
import { getMediaUrl } from '../../../../utils/Media';

const PhotoWrapper = styled.div`
  svg {
    width: 100%;
    height: 100%;
  }
`;

type AtiraLinkLeftSideProps = {
  atiraLink: AtiraLink;
};

export const AtiraLinkLeftSide: React.FC<AtiraLinkLeftSideProps> = ({
  atiraLink,
}) => {
  const {
    image,
    title,
    bio,
    map,
    email,
    phone,
    website,
    customLinks = [],
  } = atiraLink;

  return (
    <div className="atira-card p-2 d-flex flex-column gap-3 align-items-center h-100">
      <div className="user-pfp overflow-hidden">
        {image ? (
          <img
            src={
              isString(image)
                ? getMediaUrl(image!)
                : URL.createObjectURL(image!)
            }
            alt={`${title}-image`}
            className="w-100 h-100 d-inline-block object-fit-cover"
          />
        ) : (
          <PhotoWrapper className="w-100 h-100 d-inline-block">
            <AtiraIcon icon={faCircleUser} />
          </PhotoWrapper>
        )}
      </div>

      <h1 className="title text-break">{title}</h1>

      {bio ? <p className="bio text-center text-break">{bio}</p> : null}

      <div className="row justify-content-center align-items-center g-2 w-100">
        {email ? (
          <div className="col-12 gx-0">
            <a
              href={`mailto:${email}`}
              className="social-link gap-2 py-2 px-1 w-100 d-flex align-items-center justify-content-center text-decoration-none"
            >
              <div className="d-flex align-items-center justify-content-center">
                <i className="fa-solid fa-envelope"></i>
              </div>
              <p className="m-0">Send Email</p>
            </a>
          </div>
        ) : null}

        {website ? (
          <div className="col-12 gx-0">
            <a
              href={website.startsWith('http') ? website : `https://${website}`}
              className="social-link gap-2 py-2 px-1 w-100 d-flex align-items-center justify-content-center text-decoration-none"
            >
              <div className="d-flex align-items-center justify-content-center">
                <i className="fa-solid fa-globe"></i>
              </div>
              <p className="m-0">Visit website</p>
            </a>
          </div>
        ) : null}

        {map ? (
          <div className="col-12 gx-0">
            <a
              href={map.startsWith('http') ? map : `https://${map}`}
              className="social-link gap-2 py-2 px-1 w-100 d-flex align-items-center justify-content-center text-decoration-none"
            >
              <div className="d-flex align-items-center justify-content-center">
                <i className="fa-solid fa-location-dot"></i>
              </div>
              <p className="m-0">My Location</p>
            </a>
          </div>
        ) : null}

        {phone ? (
          <div className="col-12 gx-0">
            <a
              href={`tel:${phone}`}
              className="social-link gap-2 py-2 px-1 w-100 d-flex align-items-center justify-content-center text-decoration-none"
            >
              <div className="d-flex align-items-center justify-content-center">
                <i className="fa-solid fa-phone"></i>
              </div>
              <p className="m-0">Make a Call</p>
            </a>
          </div>
        ) : null}

        {customLinks?.map((link, index) =>
          link.label?.length && link.value?.length ? (
            <div className="col-12 gx-0" key={`${link}-${index}`}>
              <a
                href={
                  link.value.startsWith('http')
                    ? link.value
                    : `https://${link.value}`
                }
                className="social-link gap-2 py-2 px-1 w-100 d-flex align-items-center justify-content-center text-decoration-none"
              >
                <div className="d-flex align-items-center justify-content-center">
                  <i className="fa-solid fa-link"></i>
                </div>
                <p className="m-0">{link.label}</p>
              </a>
            </div>
          ) : null,
        )}
      </div>
    </div>
  );
};
