import styled from 'styled-components';

import { Breakpoints } from '../theme/Breakpoints';
import { Spacing } from '../theme/Spacing';
import { Flex } from './Flex';

type PageHeaderProps = {
  leftComponent: React.ReactNode;
  rightComponent: React.ReactNode;
};

const StyledPageHeader = styled(Flex)`
  justify-content: space-between;

  @media (max-width: ${Breakpoints.TABLET}) {
    flex-direction: column-reverse;
    gap: ${Spacing.s};
  }
`;

export const PageHeader: React.FC<PageHeaderProps> = ({
  leftComponent,
  rightComponent,
}) => {
  return (
    <StyledPageHeader>
      <Flex gap="m">{leftComponent}</Flex>
      <Flex justifyContent="end">{rightComponent}</Flex>
    </StyledPageHeader>
  );
};
