import React from 'react';

import { AtiraLink } from '../../../../model/atira-link/AtiraLink';

interface AtiraLinkProps {
  socials?: AtiraLink['socials'];
}

export const AtiraLinkRightSide: React.FC<AtiraLinkProps> = ({ socials }) => {
  return (
    <div className="col-12 gy-3">
      <div className="atira-card p-2 d-flex flex-column gap-3 align-items-center h-100">
        <div className="row w-100">
          <div className="col-12 gx-0">
            <div className="d-flex flex-column gap-2">
              {socials?.map((social, index) => (
                <a
                  key={`${social.label}-${index}`}
                  href={social.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  data-handler={social.handle}
                  className="trackable-social-link-target social-link gap-2 py-2 px-3 w-100 d-flex align-items-center justify-content-center text-decoration-none"
                >
                  <div className="d-flex align-items-center justify-content-center">
                    <i
                      className={`fa-brands fa-${social.handle.toLowerCase() === 'twitter' ? 'x-twitter' : social.handle.toLowerCase()}`}
                    ></i>
                  </div>
                  <p className="m-0 text-truncate">{social.label}</p>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
