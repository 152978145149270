/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { Flex } from '../../../components/Flex';
import { GenericTokenDto } from '../../../model/user/dto/GenericTokenDto';
import { SignupDto } from '../../../model/user/dto/SignupDto';
import { Breakpoints } from '../../../theme/Breakpoints';
import { Spacing } from '../../../theme/Spacing';
import { RegisterWrapper } from '../RegisterWrapper';
import { SignupEmail } from './SignupEmail';
import { SignupToken } from './SignupToken';

const PageWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 0 ${Spacing.s};

  @media (min-width: ${Breakpoints.TABLET}) {
    padding: 0 ${Spacing.xxl};
  }

  @media (min-width: ${Breakpoints.DESKTOP}) {
    padding: 0 ${Spacing.xxxl};
  }
`;

enum SIGNUP_STEPS {
  EMAIL = 'EMAIL',
  TOKEN = 'TOKEN',
}

export const Signup: React.FC = () => {
  const [step, setStep] = useState<SIGNUP_STEPS>(SIGNUP_STEPS.EMAIL);
  const formMethods = useForm<SignupDto & GenericTokenDto>({
    defaultValues: { email: '', token: '' },
  });

  return (
    <RegisterWrapper>
      <PageWrapper>
        <FormProvider {...formMethods}>
          {step === SIGNUP_STEPS.EMAIL ? (
            <SignupEmail updateStep={() => setStep(SIGNUP_STEPS.TOKEN)} />
          ) : (
            <SignupToken />
          )}
        </FormProvider>
      </PageWrapper>
    </RegisterWrapper>
  );
};
