import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Images } from '../../assets';
import { AtiraColumn } from '../../components/AtiraColumn';
import { AtiraRow } from '../../components/AtiraRow';
import { Flex } from '../../components/Flex';
import i18n, { AppLangs } from '../../i18n';
import { Breakpoints } from '../../theme/Breakpoints';
import { Spacing } from '../../theme/Spacing';

const Container = styled(Flex)<{ onForgotPasswordPage: boolean }>`
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.sub};

  @media (min-width: ${Breakpoints.DESKTOP}) {
    background-image: ${({ onForgotPasswordPage }) =>
      !onForgotPasswordPage
        ? `linear-gradient(
          to ${i18n.language === AppLangs.AR ? 'right' : 'left'},
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.1) 6%,
          rgba(255, 255, 255, 0.5) 22.5%,
          rgba(255, 255, 255, 0.6) 25.5%,
          rgba(255, 255, 255, 0.7) 30%,
          rgba(255, 255, 255, 0.8) 36%,
          rgba(255, 255, 255, 0.9) 42%,
          rgba(255, 255, 255, 1) 48%
        ),
        url(${Images.RegisterBackground})`
        : `url(${Images.RegisterBackground})`};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    position: relative;
    z-index: 1;
  }
`;

const ForgotPassLayer = styled(Flex)`
  display: none;
  background-color: ${({ theme }) => theme.light};
  opacity: 0.9;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  @media (min-width: ${Breakpoints.DESKTOP}) {
    display: block;
  }
`;

const InnerWrapper = styled(Flex)`
  padding: 0 ${Spacing.s};
  padding-bottom: ${Spacing.m};
  flex-direction: column;
`;

const StyledAtiraRow = styled(AtiraRow)`
  @media (min-width: ${Breakpoints.DESKTOP}) {
    align-items: center;
  }
`;

type RegisterWrapperProps = {
  children: React.ReactNode;
};

export const RegisterWrapper: React.FC<RegisterWrapperProps> = ({
  children,
}) => {
  const location = useLocation();

  const onForgotPasswordPage = location.pathname.includes('forgot-password');

  const onLoginSignUpPage =
    location.pathname.includes('login') || location.pathname.includes('signup');

  return (
    <Container onForgotPasswordPage={onForgotPasswordPage}>
      {onForgotPasswordPage ? <ForgotPassLayer /> : null}

      <StyledAtiraRow
        gutter={0}
        justify={onLoginSignUpPage ? 'start' : 'center'}
      >
        <AtiraColumn xs={24} sm={24} md={24} lg={12} xl={12}>
          <InnerWrapper>{children}</InnerWrapper>
        </AtiraColumn>
      </StyledAtiraRow>
    </Container>
  );
};
