import { faX } from '@fortawesome/free-solid-svg-icons/faX';
import { Modal as AntdModal } from 'antd';
import styled from 'styled-components';

import { Spacing } from '../../theme/Spacing';
import { Button } from '../Button';
import { Flex } from '../Flex';
import { ParagraphProps, Text } from '../Text';

const StyledAntdModal = styled(AntdModal)`
  .ant-modal-close {
    display: none;
  }

  .ant-modal-content {
    background-color: ${({ theme }) => theme.light};
  }
`;

const CloseButton = styled(Button)`
  margin: 0;
  padding: 0;
  background-color: ${({ theme }) => theme.transparent};
  color: ${({ theme }) => theme.gray};
`;

const StyledTitle = styled(Text)`
  font-size: 1.5rem !important;
  font-weight: bold;
  text-align: start !important;
  color: ${({ theme }) => theme.gray};
`;

const StyledHr = styled.hr`
  height: 1.5px;
  opacity: 1;
  background-color: ${({ theme }) => theme.lightgray};
  width: 100%;
  border: 0;
  margin-bottom: ${Spacing.m};
`;

const ModalTitleWrapper = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.light};
`;

interface ModalProps {
  children: React.ReactNode;
  title?: string;
  titleProps?: ParagraphProps;
  content?: React.CSSProperties;
  overlay?: React.CSSProperties;
  open: boolean;
  onClose?: VoidFunction;
}

export const Modal: React.FC<ModalProps> = ({
  onClose,
  children,
  content,
  overlay,
  title,
  titleProps = {},
  open,
}) => {
  return (
    <StyledAntdModal
      open={open}
      onCancel={onClose}
      footer={null}
      title={
        <ModalTitleWrapper>
          <StyledTitle {...titleProps}>{title}</StyledTitle>
          <CloseButton icon={faX} iconWidth="lg" onClick={onClose} />
        </ModalTitleWrapper>
      }
    >
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        <StyledHr />

        {children}
      </Flex>
    </StyledAntdModal>
  );
};
