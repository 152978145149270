import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { AtiraIcon } from '../../../components/AtiraIcon';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';

const Container = styled(Flex)`
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-top: ${Spacing.m};
`;

const Wrapper = styled(Flex)`
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: ${Spacing.s};
  justify-content: center;
`;

const StyledLink = styled(Link)`
  background-color: ${({ theme }) => theme.main};
  color: ${({ theme }) => theme.light};
  font-size: 1.2rem;
  height: 3rem;
  padding: ${Spacing.s} ${Spacing.m};
  width: 100%;
  border-radius: ${Rounded.lg};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.lightMain};
    color: ${({ theme }) => theme.light};
  }
`;

export const ForgotPasswordSuccess: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Wrapper>
        <AtiraIcon icon={faCheckCircle} color="main" size="6x" />

        <Flex flexDirection="column" gap="m" marginTop="xl" width={'100%'}>
          <Text fontWeight={'bold'} fontSize="l" align="center">
            {t('common.successful')}
          </Text>

          <Text color="darkerSub" align="center">
            {t('resetpassword.success.paragraph')}
          </Text>

          <StyledLink to={'/login'}>{t('common.login')}</StyledLink>
        </Flex>
      </Wrapper>
    </Container>
  );
};
