import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import React from 'react';
import styled from 'styled-components';

import { Button } from '../../../components/Button';
import i18n, { AppLangs } from '../../../i18n';
import { Breakpoints } from '../../../theme/Breakpoints';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';

const BackButton = styled(Button)`
  border-radius: ${Rounded.circle};
  margin: 0;
  padding: ${Spacing.s};
  width: 2.5rem !important;
  height: 2.5rem;

  @media (min-width: ${Breakpoints.DESKTOP}) {
    display: none;
  }
`;

const arrowIcon =
  i18n.language === AppLangs.AR ? faChevronRight : faChevronLeft;

type ForgotPasswordBackStepButtonProps = {
  onClick: VoidFunction;
};

export const ForgotPasswordBackStepButton: React.FC<
  ForgotPasswordBackStepButtonProps
> = ({ onClick }) => {
  return <BackButton icon={arrowIcon} onClick={onClick} />;
};
